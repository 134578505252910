import React, { useEffect, useState } from 'react';
import { GetHttp } from '../../assets/connector';
import { Table } from 'react-bootstrap';
import Loader from '../../common/spinner';

export function KeycloakEvents({ service, endpointid }){

    function fetch(id){
        GetHttp(`/endpoints/${service}/request/keycloak_events?max=50`).then(res=>{
            if(res.ok){
                res.json().then(json=>setEvents(json));
            }
        })
       
    }

    const [events,setEvents] = useState();
    useEffect(()=>fetch(),[]);
    if(events){

    return(<>
        <Table className='position-relative' responsive="xl">
                <tbody>
                
                    <tr>
                        <th>Тип</th>
                        <th>Клиент</th>
                        <th>IP</th>
                        <th>scope</th>
                        <th>Username</th>
                        <th>Дата</th>
                        
                    </tr>
                    {events.map((e, i) => {
                        return (
                            <tr key={i}>
                                <td >{e.type}</td>
                                <td>{e.clientId}</td>
                                <td >{e.ipAddress}</td>
                                <td>{e.details.scope}</td>
                                <td>{e.details.username}</td>
                                <td>{new Date(e.time).toLocaleString()}</td>
                                
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
            
    
    </>)
    }else {
        return(<Loader />)
    }

}