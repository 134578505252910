import React, { useEffect, useState } from 'react';
import { MenuLayout } from '../common/layout';
import { GetHttp, PostHttp } from '../assets/connector';
import { Button, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import {useResponseStatusCheck } from '../common/common';

export function Services({menu}){    

    const checkStatus = useResponseStatusCheck();
    function TableItem({name,value,onClick,className,style}){

        return(<div style={style}>
            <div className='fw-bold'>
                {name}
            </div>
            <div className={className} onClick={onClick}>
                {value}
            </div>
        </div>)
        }

        const [services,setServices] = useState({content:[]});

        function fetchServices(){
            GetHttp("/services")
            .then(res=>{
                if(res.ok){
                    checkStatus(res.status);
                    res.json().then(json=>setServices(json));
                }
            })
        }

        useEffect(()=>{fetchServices()},[])

        function ServiceStatus(remote){
        
            if(remote.accessTokenTime === 0 || remote.accessTokenTime+remote.accessExpiresIn < Date.now()){
                return(<div>Нет</div>)
            }else {
                return(<div>Да</div>)
            }
        }

        function TokenTime({remote}){
        
            if(remote.accessTokenTime === 0){
                return(<div>Нет</div>)
            }else {
                return(<div>{new Date(remote.accessTokenTime).toLocaleString()}</div>)
            }
        }
        const nav = useNavigate();
        
        function viewService(service){
            nav("/services/config",{state:{service:service}})
        }

        return(<>
            <MenuLayout menu={menu}>
                <div className='bg-white p-2'>
                <Table responsive>
                        <tbody>
                            
                            {services.content.map((em,i)=>{
                                return(
                                    <tr key={i}>
                                        <td>
                                            <TableItem name="Название" value={em.name} onClick={()=>viewService(em)} className="text-primary" style={{cursor:"pointer"}} />
                                        </td>
                                        <td>
                                            <TableItem name="Пользователь" value={em.username}/>
                                        </td>
                                        <td>
                                            <TableItem name="Scope" value={em.scope}/>
                                        </td>
                                        <td>
                                            <TableItem name="Время токена" value={<TokenTime remote={em}/>}/>
                                        </td>
                                        <td>
                                            <TableItem name="Активный" value={<ServiceStatus remote={em}/>}/>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </div>
               
               
            </MenuLayout>    
            
        </>)
}
