import React, { useEffect, useReducer, useState } from 'react';
import { MenuLayout } from '../common/layout';
import { DeleteHttp, GetHttp, PostHttp } from '../assets/connector';
import { Button, Form ,Badge} from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

export function ConfigService({menu}){
    
    const location = useLocation();
    const [responseState,setResponseState] = useState({})
    const [endpoints,setEndpoints] = useState([])
    

    function fetchEndpoints(){
        GetHttp(`/endpoints`,{id:location.state.service.id})
        .then(res=>{
            if(res.ok){
                res.json().then(json=>{
                    setEndpoints(json);
                })
                
            }
        })
    }
    useEffect(()=>{fetchEndpoints()},[])

      
    
function  onRequest(endpoint){
    GetHttp(`/endpoints/${location.state.service.id}/request/${endpoint}`)
    .then(e=>{
        if(e.ok){
            e.json().then(json=>{
                setResponseState({status:e.status,bg:"success",id:endpoint});
            })
            .catch(er=>setResponseState({status:500,bg:"danger",id:endpoint}));
        }else {
            if(e.status>=200 && e.status<=260){
                setResponseState({status:e.status,bg:"primary",id:endpoint});
            }else {
                setResponseState({status:e.status,bg:"danger",id:endpoint});
            }
        }
    })
}



function onDelete(endpoint){
    DeleteHttp(`/endpoints`,{id:endpoint})
    .then(()=>{
        fetchEndpoints();
        
    });

}

    function Cell({name,value,className}){

        return(
            <div className={className}>
                <div className='fw-bold'>{name}</div>
                <div>{value}</div>

            </div>
        )
    }

return(<>
        <MenuLayout menu={menu}>
                <div className='bg-white p-2'><h5>Настройки сервиса</h5></div>
                <div className='bg-white p-2'><b>Название: </b>{location.state.service.name}</div>
                <div className='bg-white p-2'><b>Client id: </b>{location.state.service.clientId}</div>
                <div className='bg-white p-2'><b>Username: </b>{location.state.service.username}</div>
                <div className='bg-white p-2'><b>Scope: </b>{location.state.service.scope}</div>
                <div className='bg-white p-2'><b>Адрес сервера: </b>{location.state.service.authority}</div>
                <div className='bg-white p-2'><b>Путь (меню): </b>{location.state.service.path}</div>
                <div className='bg-white p-2'><b>Время обновления токена: </b>{ new Date(location.state.service.accessTokenTime).toLocaleString()}</div>
                <div className="mt-1 bg-white p-1"> 
                    {endpoints.map(endpoint=>{
                        
                                return(<div className='m-2 p-1 d-flex border'>
                                    <div>
                                        <Cell name="Метод" value={endpoint.httpMethods.map(e=>e+" ")} className="m-2 p-1"/>
                                        <Cell name="Опции" value={endpoint.options.map(e=>e+" ")} className="m-2 p-1"/>
                                    </div>
                                    <div>
                                        <Cell name="URL" value={endpoint.url} className="m-2 p-1"/>
                                        <Cell name="Название" value={endpoint.name} className="m-2 p-1"/>
                                        
                                    </div>
                                    <div>
                                        <Cell name="Путь" value={endpoint.path} className="m-2 p-1"/>
                                    </div>
                                    <div>
                                        {endpoint.id===responseState.id?  <Cell name="Ответ" value={<Badge bg={responseState.bg}>{responseState.status}</Badge>} className="m-2 p-1"/> : ''}
                                        
                                    </div>
                                    <Cell name="Тест запрос" value={<Button size="sm"  onClick={()=>onRequest(endpoint.id)}><i className="bi bi-arrow-left-right"></i> Get запрос</Button>} className="ms-auto p-1"/>
                                    <Cell name="Удалить" value={<Button size="sm"  onClick={()=>onDelete(endpoint.id)} variant='outline-danger'> Удалить</Button>} className="ms-2 p-1"/>
                                </div>)    
                            
                })}
                
                   <EnpointTable onFetch={()=>fetchEndpoints()}/>
                </div>
                
        </MenuLayout></>)
}

function EnpointTable({onFetch}){
    
    function reducer(state,act){
        state[act.type] = act.value;
        return state;
    }
    const location = useLocation();
    const [newEndpont,dispatch] = useReducer(reducer,{httpMethods:['GET'],url:'',name:'',path:'',remoteService:location.state.service.id,options:[]});
    const [errorEndpoint,setErrorEndpoint] = useState('');

    function addHttpMethod(event,method){
        if(event.target.checked){
            newEndpont.httpMethods.push(method);
        }else{
            const e = newEndpont.httpMethods.filter(f=>f!==method);
            newEndpont.httpMethods=e;
        }
        
        
    }

    function addOption(event,option){
        if(event.target.checked){
            newEndpont.options.push(option);
        }else{
            const e = newEndpont.options.filter(f=>f!==option);
            newEndpont.options=e;
        }
        
        
    }
    const onAddEndpoint = ()=>{
        PostHttp(`/endpoints`,newEndpont)
        .then(e=>
            {if(e.ok){
                onFetch();
                
            }else {                   
                e.json().then(json=>setErrorEndpoint(json.message))
            }
    })
    } 
    return(<>
    {errorEndpoint !==''? <div className='text-center text-danger'>{errorEndpoint}</div> :'' }
        <div className='d-flex align-items-center border p-1 m-1'>
                        
                        <Form.Check label="POST" className='mx-2'onChange={(e)=>addHttpMethod(e,'POST')}/>
                        <Form.Check label="PUT" className='mx-2'onChange={(e)=>addHttpMethod(e,'PUT')}/>
                        <Form.Check label="DELETE" className='mx-2'onChange={(e)=>addHttpMethod(e,'DELETE')}/>

                        <Form.Check label="SEARCH" className='mx-2'onChange={(e)=>addOption(e,'search')}/>
                        <Form.Check label="API" className='mx-2'onChange={(e)=>addOption(e,'API')}/>
                        
                        <span className="ms-2">URL:</span>
                        <Form.Control 
                            size="sm"  
                            placeholder='http://myapi.com/endpoint' 
                            className='mx-2' 
                            onChange={(e)=>dispatch({type:'url',value:e.target.value})}/>
                        <Form.Control 
                            size="sm"  
                            placeholder='Путь (только латинскими,без пробелов)' 
                            className='mx-2' 
                            onChange={(e)=>dispatch({type:'path',value:e.target.value})}/>
                        <Form.Control 
                            size="sm"  
                            placeholder='Название в меню' 
                            className='mx-2' 
                            onChange={(e)=>dispatch({type:'name',value:e.target.value})}/>

                        <Button size="sm" onClick={onAddEndpoint}>Добавить</Button>
                        
                </div> 
    </>)
}


