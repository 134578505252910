import React from 'react';
import { MenuLayout } from '../common/layout';
import { useMenu } from '../App';

export function Main({menu}){    

        return(<>
            <MenuLayout menu={menu}>
                <div className='bg-white p-2'>First main page</div>
            </MenuLayout>    
            
        </>)
}