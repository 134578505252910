import { Container, Row, Form, Button, Alert } from "react-bootstrap";
import { useState } from "react";

export default function  FirstRunWizard(){

    const [appcfg,setAppcfg] = useState({orgName:'',adminEmail:'',firstName:''});
    const [message,setMessage] = useState();
    
    function sendInitapp(){
        if(appcfg.orgName.length>0 && appcfg.adminEmail.length>0 && appcfg.firstName.length){
            return fetch("/api/firstrun",{method:'post',body:JSON.stringify(appcfg),headers:{'Content-Type':"application/json"}})
            .then(e=>{
                if(e.ok){

                }else {
                   e.json().then(json=>setMessage(json.message));
                    
                }
            })
        }else {
            setMessage("Все поля обязятельны для заполнения")
        }
    }

    return(<>
        <Container className="bg-white mt-4">
        <div className=" h4 text-center my-2 p-2">Первичная настройка приложения</div>
        {message!= undefined ?<Alert variant="danger">{message}</Alert>:''}
            <Row>
                <Form.Group className="mb-3">
                    <Form.Label>Название организации</Form.Label>
                    <Form.Control type="text" placeholder="ООО назавние организации" onChange={(e)=>setAppcfg({orgName:e.target.value,adminEmail:appcfg.adminEmail,firstName:appcfg.firstName})}/>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Учетная запись администратора</Form.Label>
                    <Form.Control type="email" placeholder="name@example.com" onChange={(e)=>setAppcfg({orgName:appcfg.orgName,adminEmail:e.target.value,firstName:appcfg.firstName})}/>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Имя администратора</Form.Label>
                    <Form.Control type="text" onChange={(e)=>setAppcfg({orgName:appcfg.orgName,adminEmail:appcfg.adminEmail,firstName:e.target.value})}/>
                </Form.Group>
                <Button variant="success" onClick={()=>sendInitapp()}>Продолжить</Button>
            </Row>
            
            
            
        </Container>
    </>)
}