import React, { useEffect, useReducer, useState } from 'react';
import { DeleteHttp, GetHttp, PostHttp, PutHttp } from '../../assets/connector';
import { Table, Button, Pagination, Form, Modal, FormLabel } from 'react-bootstrap';
import Loader from '../../common/spinner';
import { Customer } from '../../common/skladsystem/customer';
import { PagenationPanel, SearchForm } from '../../common/tables';
import { InstrumentCatalog } from '../../common/skladsystem/catalog';

const colNumber =6;

export function Customers({ service, endpointid }) {

    const [page, setPage] = useState();
    const [load, setLoad] = useState(true);
    const [endpoint,setEndpoint] = useState({httpMethods:[]});
    const [lastSearch,setLastSearch] = useState('');
    const [filterid,setFilterid] = useState(-1);

    function fetchEndpoint(id){
        GetHttp(`/endpoints/${id}`).then(res=>{
            if(res.ok){
                res.json().then(json=>setEndpoint(json));
            }
        })
    }
    function fetchCustomers(page,search){
        
            GetHttp(`/endpoints/${service}/request/customers?page=${page}&size=20&searh=${search}&sort=id,DESC`)
                .then(res => {
                    if(search!== undefined){setLastSearch(search);}
                    if (res.ok) {
                    res.json().then(json => {
                        setPage(json);
                        setLoad(false);
                    })
                    }
            })
        
    }

    function search(search){
        
        GetHttp(`/endpoints/${service}/request/customersearch?search=${search}&size=20&sort=id,DESC&filterid=${filterid}`)
            .then(res => {
                if(search!== undefined){setLastSearch(search);}
                if (res.ok) {
                res.json().then(json => {
                    setPage(json);
                    setLoad(false);
                })
                }
        })
    
}
     

    useEffect(() => {
        setLoad(true);
        fetchCustomers(0,'');
        fetchEndpoint(endpointid);

    }, [endpointid]);

    if (load) {
        return (<Loader />)
    } else {
            return (<>
                    <PageableTable page={page} 
                        endpoint={endpoint} 
                        update={(pageNumber,search) => {fetchCustomers(pageNumber,search);}}>
                        <div className='d-flex'>
                            <Form.Select onChange={(event)=>setFilterid(event.target.value)}>
                                <option value="-1">Все</option>
                                <option value="0">Активный</option>
                                <option value="1">Заблокирован</option>
                            </Form.Select>
                            <SearchForm  onSearch={(str)=>search(str)}/>    
                        </div>
                        
                    </PageableTable>
            </>)
       
    }
}
function ButtonBar({  colCount, onAddClick ,children}) {        
        return (<>
            <tr>
                <td colSpan={colCount}>
                    <div className='d-flex'>
                        <div><Button variant="link" onClick={onAddClick} >Добавить</Button></div>
                         <div className='ms-auto'>
                            {children}
                        </div>
                    </div>
                </td>
            </tr>
            </>)
    
}

function PageableTable({ page,  endpoint, update,children }) {
    
    const [showCreateDialog,setShowCreateDialog] = useState(false);
    const [showCustomer,setShowCustomer] = useState();
    const [showInstrument,setShowInstrument] = useState();

    function handleBlock(id) {

		GetHttp(`/endpoints/${endpoint.remoteService}/request/disablecustomer/${id}`)
			.then(res => {
				if (res.ok) {
					update(page.pageable.pageNumber);
				}
			})
	}

        if(showCustomer!== undefined){
            return(<Customer id={showCustomer} endpoint={endpoint} onHide={()=>{setShowCustomer();update(0)}}/>)
        
        }else if(showInstrument!==undefined){
           return <InstrumentCatalog endpoint={endpoint} customer={showInstrument} onHide={()=>setShowInstrument()}/>
        }else {
            return (<><div className='bg-white p-1'>
                <CreateItem show={showCreateDialog}  onHide={()=>{setShowCreateDialog(false)}} service={endpoint.remoteService} endpoint={endpoint.id}/>
                <Table className='position-relative' responsive="xl">
                <tbody>
                <ButtonBar
                    colCount={colNumber}
                    onAddClick={()=>{setShowCreateDialog(true)}}>
                            {children}
                </ButtonBar>
                    <tr>
                        <th>Название</th>
                        <th>Баланс</th>
                        <th>Тариф</th>
                        <th>Кол-во инструмента</th>
                        <th>Кол-во сотрудников</th>
                        <th>Статус</th>
                        
                    </tr>
                    {page.content.map((e, i) => {
                        return (
                            <tr key={i}>
                                <td className='text-primary' onClick={()=>setShowCustomer(e.id)} style={{cursor:'pointer'}}>{e.name}</td>
                                <td>{e.balance}</td>
                                <td>{e.tariff.name}</td>
                                <td className='text-primary' onClick={()=>setShowInstrument(e.id)} style={{cursor:'pointer'}}>{e.totalInstrumentCount}</td>
                                <td>{e.totalAccounts}</td>
                                <td>{e.customerState==="normal"?<span className="text-success">Работает<i className="bi bi-ban ms-2 text-danger" onClick={()=>handleBlock(e.id)}></i></span>:<span className="text-danger">Заблокирован<i className="bi bi-play-circle ms-2 text-success" onClick={()=>handleBlock(e.id)}></i></span>}</td>
                                
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
            <PagenationPanel page={page} nextPage={(page)=> update(page)} />
            </div></>)
        }
    }



function CreateItem({show,onHide,service,endpoint}){

    const [tariffs,setTariffs] = useState([]);

    function fetchTariffs(){
        
        GetHttp(`/endpoints/${service}/request/tariffs`)
            .then(res => {
                if (res.ok) {
                res.json().then(json => {
                    setTariffs(json);
                    dispach({ name: "tarif", value: json[0].id })
                })
                }
        })
    
    }
    useEffect(()=>fetchTariffs(),[]);

    function validInput(inputs){
        
        for(let e of inputs){
            if(e.value.length===0){
                e.classList.add('is-invalid');
            }else {
                e.classList.remove('is-invalid');
            }
        }
        
        
    }

    function reducer(state,act){
        state[act.name] = act.value;
        return state;

    }

    const onSend=()=>{
        const inputs =Array.from( document.querySelectorAll(".modal-body input"));
        validInput(inputs);
        if(inputs.filter(f=>f.value.length===0).length===0){
            PostHttp(`/endpoints/${service}/request/customer`,newItem)
            .then(()=>onHide());
        }
    }

    
    const [newItem,dispach] = useReducer(reducer,{});

    return(<>
    
        <Modal show={show} onHide={onHide}>
            <Modal.Body>
                <Form.Group className="mb-3">
                    <FormLabel>Название</FormLabel>
                    <Form.Control  type="text" onChange={(e) => dispach({ name: "name", value: e.target.value })} />
                </Form.Group>
                <Form.Group className="mb-3">
                    <FormLabel>Описание</FormLabel>
                    <Form.Control  type="text" onChange={(e) => dispach({ name: "description", value: e.target.value })} />
                </Form.Group>
                <Form.Group className="mb-3">
                    <FormLabel>Тариф</FormLabel>
                    <Form.Select  type="text" onChange={(e) => dispach({ name: "tarif", value: e.target.value })} >
                        {tariffs.map((t,i)=><option key={i} value={t.id}>{t.name}</option>)}
                    </Form.Select>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Отменить
          </Button>
          <Button variant="primary" onClick={onSend} >
            Добавить
          </Button>
        </Modal.Footer>
        </Modal>
    </>)

}