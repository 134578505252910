import React, {useEffect, useState} from 'react';
import { MenuLayout } from '../common/layout';
import { Table,Button } from 'react-bootstrap';
import { GetHttp, PostHttp } from '../assets/connector';
import {useResponseStatusCheck } from '../common/common';


export function Scripts({menu}){
    const checkStatus = useResponseStatusCheck();
    
    function TableItem({name,value}){
    

    return(<div className=''>
            <div className='fw-bold'>
                {name}
            </div>
            <div>
                {value}
            </div>
        </div>)
    }

    const [scripts,setScripts] = useState({content:[]});

    function fetchScripts(){
        GetHttp("/script").then((res)=>{
            if(res.ok){
                checkStatus(res.status);
                res.json().then(json=>setScripts(json));
            }
        })
    }

    useEffect(()=>fetchScripts(),[])

    const onAddScript=()=>{
        PostHttp("/script",{})
        .then(()=>fetchScripts());
    }

    return(<>
        <MenuLayout menu={menu}>
        <Table responsive>
        <tbody>
                            <tr>
                                <td colSpan={6} className=''><Button variant="link" onClick={onAddScript}>Добавить скрипт</Button></td>
                            </tr>
                            {scripts.content.map((em,i)=>{
                                
                                return(
                                    <tr key={i}>
                                        
                                        <td>
                                            <TableItem name="Название" value={em.name}/>
                                        </td>
                                        <td>
                                            <TableItem name="Дата создания" value={new Date(em.created).toLocaleString()}/>
                                        </td>
                                        <td>
                                            
                                        </td>
                                        <td>
                                            
                                        </td>
                                        <td>
                                            
                                        </td>
                                    </tr>
                                )
                            })}
                            
                        </tbody>

                    </Table>
            </MenuLayout></>)
}