import { useEffect,useState } from "react";
import { useNavigate } from 'react-router-dom';
import { GetHttp } from "../assets/connector";

export async function checkConnection(response){

    if(response.ok){
        let result;
        await response.json().then(json=>result = json);
        return result;
    }else {
        response.json().then(err=> {
                console.log(err.message)
        });
    }

}

export function useResponseStatusCheck(){
    const nav = useNavigate();

    function check(statuscode){
        if(statuscode == 402){
            nav("/company")
            
        }
    }
    return check;
}


