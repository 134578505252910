import React, { useEffect, useReducer, useState } from 'react';
import { MenuLayout } from '../common/layout';
import { useNavigate } from 'react-router-dom';
import { DeleteHttp, GetHttp, PostHttp, PutHttp } from '../assets/connector';
import { Table, Button, Pagination, Form, Modal, FormLabel } from 'react-bootstrap';
import Loader from '../common/spinner';
import { Endpoint } from './skladsystem/endpoint';
import { PagenationPanel, SearchForm } from '../common/tables';
import {useResponseStatusCheck } from '../common/common';

export function fetchAll(service, endpoint,pageNumber, callback,search) {

    GetHttp(`/endpoints/${endpoint}/fields`)
        .then(res => {
            if (res.ok) {
                res.json().then(fjson => {
                    GetHttp(`/endpoints/${service}/request/${endpoint}`,{page:pageNumber,size:5,search:search})
                        .then(res => {
                            if (res.ok) {
                                res.json().then(ejson => {
                                    callback(fjson, ejson);
                                })

                            }
                        })
                })

            }
        })
    

}

export function ServiceCRUD({ service, endpointid }){


    return(<>
        <MenuLayout>
            <Endpoint service={service} endpointid={endpointid} />
        </MenuLayout>    
    </>)
}

export function ContentServiceCRUD({ service, endpointid }) {

    const [page, setPage] = useState();
    const [list, setList] = useState();
    const [load, setLoad] = useState(true);
    const nav = useNavigate();
    const [fields, setFields] = useState([]);
    const [endpoint,setEndpoint] = useState({httpMethods:[]});
    const [lastSearch,setLastSearch] = useState('');
    const checkStatus = useResponseStatusCheck();
    function getEndpoint(id){
        
        GetHttp(`/endpoints/${id}`).then(res=>{
            if(res.ok){
                checkStatus(res.status);
                res.json().then(json=>setEndpoint(json));
            }
        })
        
    }    

     function fetch(pageNumber,search) {
         fetchAll(service, endpointid,pageNumber, (f, e) => {
            setFields(f);
            if(search!== undefined){setLastSearch(search);}
            if (e.content !== undefined) {
                setPage(e);
                setList(undefined)
            } else {
                setList(e)
                setPage(undefined);
            }
            setLoad(false);
            
        },search===undefined?lastSearch:search)
        
    }

    useEffect(() => {
        setLoad(true);
        fetch(0,'');
        setEndpoint(getEndpoint(endpointid));

    }, [endpointid]);

    if (load) {
        return (<Loader />)
    } else {

        if (page !== undefined) {
            return (<>
                    <PageableTable page={page} fields={fields} endpoint={endpoint} update={(pageNumber,search) => {fetch(pageNumber,search);}} />
            </>)
        } else if (list !== undefined) {
            return (<>
                    <ListTable page={list} fields={fields} endpoint={endpoint} update={(search) => {fetch(0,search);}} />
            </>)
        } else {
            nav("/error", { message: 'Не удалось распознать данные' });
        }
    }
}
function ButtonBar({ create, colCount, onConfigClick, onAddClick ,enableSearch, onSearch}) {
    
        return (<>
            <tr>
                <td colSpan={colCount}>
                    <div className='d-flex'>
                        {create ? <div><Button variant="link" onClick={onAddClick} >Добавить</Button></div> : ''}
                        {enableSearch ? <div className='ms-auto d-flex'>
                            <SearchForm  onSearch={(string)=>onSearch(string)}/></div>:''} 
                        <div className={enableSearch?'':'ms-auto'}><Button variant="link" onClick={onConfigClick}><i className="bi bi-gear text-dark"></i></Button></div>
                    </div>
                </td>
            </tr>
            </>)
    
}

function PageableTable({ page, fields, endpoint, update }) {

    

    function getEnum(content) {
        if (content.length > 0) {
            const ar = [];
            for (let f in content[0]) {
                if (typeof content[0][f] === "string" || typeof content[0][f] === "number") {
                    ar.push(f);
                }
            }
            return ar;
        }
        return [];
    }

    const [showConfig, setShowConfig] = useState(false);
    const hasDelete = endpoint.httpMethods.includes('DELETE');
    const [showCreateDialog,setShowCreateDialog] = useState(false);

    function deleteItem(id){
        DeleteHttp(`/endpoints/${endpoint.remoteService}/request/${endpoint.id}`,{itemid:id})
        .then(()=>{fetchAll(endpoint.remoteService,endpoint.id,0,()=>{})})
    }

    if (showConfig) {
        return (<DataTypeConfig endpoint={endpoint} onHide={() => { setShowConfig(false); update(); }} targetEnum={getEnum(page.content)} />)

    } else {
        return (<>
        <CreateItem show={showCreateDialog}  onHide={()=>{setShowCreateDialog(false)}} service={endpoint.remoteService} endpoint={endpoint.id}/>
        <PayloadTable 
            data={page.content} 
            pagination={<PagenationPanel nextPage={(page)=>update(page)} page={page}/>}
            endpoint={endpoint}
            fields={fields}
            onDelete={(id)=>deleteItem(id)}
            buttonbar={<ButtonBar 
                create={endpoint.httpMethods.includes('PUT')} 
                colCount={hasDelete? fields.length+1 :fields.length} 
                onConfigClick={() => setShowConfig(true)} 
                onAddClick={()=>{setShowCreateDialog(true)}}
                enableSearch={endpoint.options.includes('search')}
                onSearch={(s)=>{update(0,s)}}
                />}
                />
        </>)
    }
}

function PayloadTable({data,pagination,endpoint,fields,buttonbar,onDelete}){

    const hasDelete = endpoint.httpMethods.includes('DELETE');

    

    return(<>
        <Table className='position-relative' responsive="xl">
                <tbody>
                    {buttonbar}
                    <tr>
                        {fields.map((f, i) => <td key={i} ><b>{f.name}</b></td>)}
                        {hasDelete?<td></td>:''}
                    </tr>
                    {data.map((e, i) => {
                        return (
                            <tr key={i}>
                                {fields.map((f) => <td>{dataType(e[f.target], f.type)}</td>)}
                                {hasDelete? <td className="text-end"><Button size="sm" variant='outline-danger'  onClick={()=>onDelete(e.id)}>x</Button> </td>:''}
                            </tr>
                        )
                    })}
                    {pagination!== undefined ? 
                        <tr>
                            <td colSpan={hasDelete? fields.length+1 :fields.length} >
                                {pagination}

                            </td>
                        </tr>
                    : ''}
                    
                </tbody>
            </Table>
    </>)
}

function dataType(data, type) {

    switch (type) {
        case "date": return new Date(data).toLocaleString();
        case "datetime-local": return new Date(data).toLocaleString();
        default: return data;
    }

}

function ListTable({ page, fields, endpoint, update }) {

    function getEnum(content) {
        if (content.length > 0) {
            const ar = [];
            for (let f in content[0]) {
                if (typeof content[0][f] === "string" || typeof content[0][f] === "number") {
                    ar.push(f);
                }
            }
            return ar;
        }
        return [];
    }

    const [showConfig, setShowConfig] = useState(false);
    const hasDelete = endpoint.httpMethods.includes('DELETE');
    const [showCreateDialog,setShowCreateDialog] = useState(false);

    function deleteItem(id){
        DeleteHttp(`/endpoints/${endpoint.remoteService}/request/${endpoint.id}`,{itemid:id})
        .then(()=>{fetchAll(endpoint.remoteService,endpoint.id,0,()=>{})})
    }

    if (showConfig) {
        return (<DataTypeConfig endpoint={endpoint} onHide={() => { setShowConfig(false); update(); }} targetEnum={getEnum(page)} />)

    } else {
        return (<>
        <CreateItem show={showCreateDialog}  onHide={()=>{setShowCreateDialog(false)}} service={endpoint.remoteService} endpoint={endpoint.id}/>
        <PayloadTable 
            data={page} 
            endpoint={endpoint}
            fields={fields}
            onDelete={(id)=>deleteItem(id)}
            buttonbar={<ButtonBar 
                create={endpoint.httpMethods.includes('PUT')} 
                colCount={hasDelete? fields.length+1 :fields.length}
                onConfigClick={() => setShowConfig(true)} 
                enableSearch={endpoint.options.includes('search')}
                onSearch={(s)=>{update(s)}}
                onAddClick={()=>{setShowCreateDialog(true)}} />} 
            
            />
        </>)
    }
}



function DataTypeConfig({ endpoint, onHide, targetEnum }) {

    const [fields, setFields] = useState([]);


    async function fetchFields() {
        await GetHttp(`/endpoints/${endpoint.id}/fields`)
            .then(res => {
                if (res.ok) {
                    res.json().then(json => {
                        setFields(json);
                    })

                }
            })
    }

    function onAddField() {
        PostHttp(`/endpoints/${endpoint.id}/fields`, newField)
            .then(() => {
                fetchFields();
                Array.from(document.getElementsByTagName('input')).map(e=>e.value='');
            });
    }

    useEffect(() => { fetchFields() }, [])

    function TableField({ name, value }) {
        return <div>
            <div className='p-1'><b>{name}</b></div>
            <div className='p-1'>{value}</div>
        </div>
    }



    function deleteField(id) {
        DeleteHttp(`/endpoints/${endpoint.id}/fields`, { id: id })
            .then(() => fetchFields());
    }

    function reduce(state, act) {

        state[act.name] = act.value;

        return state;

    }
    const [newField, dispach] = useReducer(reduce, { endpoint: endpoint.id, type: "string", target: "", name: "", priority: 1 });

    return (<>
    
        <Table >
            <tbody>
                <tr>
                    <td colSpan={5}><i className="bi bi-arrow-left-circle fs-3 text-success ms-2" onClick={() => onHide()} style={{ cursor: 'pointer' }}></i></td>
                </tr>
                {fields.map((f, i) =>
                    <tr key={i}>
                        <td>
                            <TableField name="Название" value={f.name} />
                        </td>
                        <td>
                            <TableField name="Цель" value={f.target} list='enum' />
                        </td>
                        <td>
                            <TableField name="Тип" value={f.type} />
                        </td>
                        <td>
                            <TableField name="Приоритет" value={f.priority} />
                        </td>
                        <td>
                            <TableField name="Удалить" value={<Button className='m-0' variant='outline-danger' size="sm" onClick={() => deleteField(f.id)}>X</Button>} />
                        </td>
                    </tr>

                )}
                <tr>
                    <td><Form.Control type="text" placeholder="" size="sm" onChange={(e) => dispach({ name: 'name', value: e.target.value })} /></td>
                    <td><input type="text" list='enum' className='form-control form-control-sm' onChange={(e) => dispach({ name: 'target', value: e.target.value })} /></td>
                    <td><Form.Select size="sm" onChange={(e) => dispach({ name: 'type', value: e.target.value })}>
                        <option value="text" selected>Строка</option>
                        <option value="datetime-local">Дата веремя</option>
                        <option value="date">Дата</option>
                        <option value="email">Email</option>
                    </Form.Select></td>
                    <td><Form.Select size="sm" onChange={(e) => dispach({ name: 'priority', value: e.target.value })}>
                        <option value={1} selected>1</option>
                        <option value={2}>2</option>
                        <option value={3}>3</option>
                    </Form.Select></td>
                    <td><Button className='m-0' variant='outline-success' size="sm" onClick={() => onAddField()}>Добавить</Button></td>
                </tr>
            </tbody>
            <datalist id='enum'>
                {targetEnum.map(e => <option value={e}>{e}</option>)}
            </datalist>
        </Table>
    </>)
}

function CreateItem({show,onHide,service,endpoint}){

    const [fields,setFields] = useState([]);
    
    function validInput(inputs){
        
        for(let e of inputs){
            if(e.value.length===0){
                e.classList.add('is-invalid');
            }else {
                e.classList.remove('is-invalid');
            }
        }
        
        
    }

    function reducer(state,act){
        state[act.name] = act.value;
        return state;

    }

    function fetchFields(endpoint){

        GetHttp(`/endpoints/${endpoint}/fields`)
        .then(res=>{
            if(res.ok){
                res.json().then(json=>setFields(json));
            }
        })

    }

    const onSend=()=>{
        const inputs =Array.from( document.getElementsByTagName('input'));
        validInput(inputs);
        if(inputs.filter(f=>f.value.length===0).length===0){
            PostHttp(`/endpoints/${service}/request/${endpoint}`,newItem)
            .then(()=>onHide());
        }
    }

    useEffect(()=>fetchFields(endpoint),[]);
    const [newItem,dispach] = useReducer(reducer,{});

    return(<>
    
        <Modal show={show} onHide={onHide}>
            <Modal.Body>
                {fields.map((f,i)=>{return(<>
                    <Form.Group key={i} className="mb-3">
                        <FormLabel>{f.name}</FormLabel>
                        <Form.Control  type={f.type} onChange={(e) => dispach({ name: f.target, value: e.target.value })} />
                    </Form.Group>
                    </>)
                })}
            </Modal.Body>
            <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Отменить
          </Button>
          <Button variant="primary" onClick={onSend} >
            Добавить
          </Button>
        </Modal.Footer>
        </Modal>
    </>)

}