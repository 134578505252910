import React, { createContext, useEffect, useRef, useState } from 'react';
import './App.css';
import { oidcSettings } from './assets/connector';
import { AuthProvider, useAuth } from "react-oidc-context";
import { GetHttp } from './assets/connector';

import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import { Unauth } from './common/unauth';
import { AccessForbidden, ErrorPage, ServiceNotConfigured } from './common/nopage';
import { Main } from './pages/main';
import { Request } from './pages/bids';
import { NewBid, ViewBid } from './common/bid';
import { ManagementPanel } from './pages/management';
import { Employees } from './pages/employees';
import { Channels } from './pages/channels';
import { UserInfo } from './pages/userinfo';
import { Employee } from './common/employee';
import { Departments } from './pages/departments';
import { Channel } from './common/channel';
import { Scripts } from './pages/scripts';
import Loader from './common/spinner';
import { Services } from './pages/services';
import { ConfigService } from './pages/configservice';
import { ServiceCRUD } from './pages/servicecrud';
import { Mail } from './common/mail';
import { Contacts } from './pages/contacts';
import { FeedNews } from './pages/feednews';
import { Company } from './pages/company';
import { Files } from './pages/files';
import { Chats } from './pages/chats';
import { Stomp } from '@stomp/stompjs';
import FirstRunWizard from './pages/init_wizard';



const baseMenu = [
  {
    name: 'Главная', path: '/main/', children:
      [{ name: 'Новости', path: "feednews", element: <FeedNews /> },
      { name: 'Список заявок', path: "bids", element: <Request /> },
      { path: "bid", element: <ViewBid />, isHidden: "" },
      { path: "newbid", element: <NewBid />, isHidden: "" },
      { name: 'Сотрудники', path: "employees", element: <Employees /> },
      { path: 'employee', element: <Employee />, isHidden: '' },
      { name: 'Контакты', path: "contacts",  element: <Contacts /> },
      { name: 'Отделы', path: "departments", element: <Departments /> }
      ]
  },
  {
    name: 'Каналы связи', path: "/ch/", children: [
      { name: "Каналы", path: "channels", element: <Channels /> },
      { name: "Скрипты", path: "scripts", element: <Scripts /> },
      { path: 'channel', element: <Channel />, isHidden: '' },
      { path: 'mail', element: <Mail />, isHidden: '' },
    ]
  },
  {
    name: 'Чаты', path: "/chats/", children: [
      { name: "Активные", path: "active", element: <Chats /> },
      { name: "Архив", path: "arhive", element: <Main /> },
    ]
  },
  {
    name: 'Файлы', path: "/storage/", children: [
      { name: "Список файлов", path: "view", element: <Files /> }
    ]
  },
  {
    name: 'Сервисы', path: "/services/", children: [
      { name: "Список", path: "view", element: <Services /> },
      { path: "config", element: <ConfigService />, isHidden: '' }
    ]
  },
  {
    path: "/userinfo",
    element: <UserInfo />,
    errorElement: <ErrorPage />,
    isHidden: ''
  },
  {
    path: "/manage/", isHidden: true, name: '',
    children: [{ path: "main", element: <ManagementPanel /> }],
    errorElement: <ErrorPage />,
  },
  {
    path: "*",
    element: <Request />,
    isHidden: true,
    errorElement: <ErrorPage />,
  },
  {
    path: "/error",
    element: <ErrorPage />,
    isHidden: "",
    errorElement: <ErrorPage />,
  },
  {
    path: "/forbidden",
    element: <AccessForbidden />,
    isHidden: "",
    errorElement: <ErrorPage />,
  }, {
    path: "/autorize",
    isHidden: '',
    element: <Unauth />,
    errorElement: <ErrorPage />
  },{
    path: "/company",
    isHidden: true,
    element: <Company />,
    errorElement: <ErrorPage />
  }
]

export let defaultMenu = []

function RouterApp() {

  return (<>
    
    <AuthProvider {...oidcSettings}>
      <App />
    </AuthProvider>
  </>)
}

function App() {

  const auth = useAuth();

  auth.events.addSilentRenewError((e)=>{
    document.location.href='/autorize'; 
  })

  if (auth.isAuthenticated) {

    return (<Autorized />)

  } else if (auth.isLoading) {
    return (<div><Loader /></div>)
  } else {
    return (<>
      <Unauth />
    </>)

  }

}

export function useWS(target){

  const socket = useRef(null)
  const [val, setVal] = useState({event:"none"})
  useEffect(()=>{
    
    const ws = Stomp.client("/websocket");
    ws.activate();
    ws.onConnect = (event)=>{      
      ws.subscribe(`/topic/${target}`,(ev)=>{setVal(ev.body)})
    }
    ws.reconnect_delay = 5000;
    ws.onDisconnect(event=>console.log(event));
    socket.current=ws;
    
    return ()=>ws.deactivate();
  },[])

  return val;

}

export const UserContex = createContext(null);
export const WebSocketContext = createContext(null)

function Autorized() {

  const [router, setRouter] = useState(createBrowserRouter([...baseMenu]))
  const [user,setUser] = useState();
  const socket = useRef(null)
  const [val, setVal] = useState({event:"none"});
  const [firstrun,setFirstRun] = useState(false);

  function connectWS(target){

    const ws = Stomp.client("ws://localhost:8080/websocket");
    ws.activate();
    ws.onConnect = (event)=>{      
      ws.subscribe(`/topic/${target}`,(event)=>setVal(event.body))
    }
    ws.reconnect_delay = 5000;
    ws.onDisconnect(event=>console.log(event));
    socket.current=ws;
  }

  function fetchUser(){
    GetHttp("/profile").then(
        (res)=>{
            if(res.ok){
                res.json().then(json=>{
                  setUser(json);
                  connectWS(json.companyToken);
                });
                
            }else{
              if(res.status ==402){
                setFirstRun(true);
              }
            }
        }
    )
}

  
  function getSubmenu(menu) {
    const submenu = menu.submenu;
    const ar1 = [];
    for (let sub of submenu) {
      if (!sub.ignore) {
        ar1.push({ name: sub.name, path: sub.path, element: <ServiceCRUD service={menu.serviceId} endpointid={sub.id} /> });
      }
    }
    return ar1;

  }

  function fetchServices() {
    
    GetHttp("/services/list")
      .then(res => {
        if (res.ok) {
          const ar = [...baseMenu];
          res.json()
            .then(json => {
              json.forEach(m => {
                if (m.submenu.length > 0) {
                  ar.push(
                    {
                      name: m.name,
                      path: "/" + m.path + "/",
                      children: getSubmenu(m)
                    })
                } else {
                  ar.push({ name: m.name, path: "/" + m.path + "/", children: [], element: <ErrorPage />, errorElement: <ServiceNotConfigured /> });
                }
              })
              defaultMenu = ar;
              const m1 = [];
              for (const item of ar) {
                if (!item.ignore || item !== undefined) {
                  m1.push(toRoute(item));
                }
              }
              const router2 = createBrowserRouter(m1.length > 0 ? m1 : [{ path: '*', element: <ErrorPage /> }]);
              setRouter(router2)
            });
        }else{
          if(res.status ==402){
            setFirstRun(true);
          }
        }
      })
  }

  

  useEffect(() => {
    fetchServices();
    fetchUser();    
  }, [])
  
  function toRoute(item) {

    const ar1 = [];
    if (item.children !== undefined) {
      for (let item2 of item.children) {
        if (item2 !== undefined && !item2.ignore) {
          ar1.push(toRoute(item2));
        }
      }
    }

    return ({
      path: item.path,
      errorElement: <ErrorPage />,
      element: item.element,
      children: ar1
    })
  }
  if(firstrun){
    return(<FirstRunWizard />);
  }else if(user){
      return (    
        <WebSocketContext.Provider value={[val]}>
          <UserContex.Provider value={[user,fetchUser]}>
            < RouterProvider router={router} />
          </UserContex.Provider>
        </WebSocketContext.Provider>

      )
  }else {
    return(<Unauth />)
  }



}

export default RouterApp;
