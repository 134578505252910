import React, {useEffect, useState,useRef, useContext} from 'react';
import { MenuLayout } from '../common/layout';
import { Image,FormControl, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { GetHttp,PostHttp} from '../assets/connector';
import Loader from '../common/spinner';
import {useResponseStatusCheck } from '../common/common';
import { WebSocketContext } from '../App';
import root from "react-shadow";
import { bidStausResolver } from '../common/bid';


const avatarWidth = 45;

export function FeedNews({menu}){
 
    const [feednews,setFeednews]= useState();
    const checkStatus = useResponseStatusCheck();
    const [wsMessage]= useContext(WebSocketContext);
    const [message,setMessage] = useState();

    function fetch(){
        GetHttp("/bid/updated?size=10&sort=bid.updated,DESC")
        .then((res)=>{
            checkStatus(res.status);
            if(res.ok){
                res.json()
                .then(json=>{
                    setFeednews(json);
                });
                
            }
        })
    }

    
    

    useEffect(()=>{
        fetch();
    },[wsMessage])

    if(feednews){
        return(<>
        <MenuLayout message={message}>
        <div className='p-1'>
            {feednews.content.map( (f,i)=>{
                return <NewsBlock item={f} key={i} setMessage={(e)=>setMessage(e)}/>
            })}
        </div>
        </MenuLayout>
        </>)
    }else {
    return(<>
        <MenuLayout message={message}>
            <div className='bg-white p-1'>
                <Loader />    
            </div>
        </MenuLayout>
    </>)
    }
}


function NewsBlock({item,setMessage}){
    const commentCount =3;
    const [bid,setBid] = useState(item);
    const [comments,setComments] = useState();
    const [ownerImg,setOwnerImg] = useState();

    function fetchAvatar(){
        const uuid = item.owner.avatar;
        if(uuid !=="/default-avatar.png" && uuid !=null){
                GetHttp("/file/uuid/" + uuid.split(".")[0])
                .then(e => {
                    if (e.ok) {
                        e.blob().then(b =>  {
                            setOwnerImg(URL.createObjectURL(b));
                        });
                    }
                })
           
        }else {
            setOwnerImg("/default-avatar.png");
        }
    
        
    }

    

    useEffect(()=>{
        fetchComment(commentCount);
        fetchAvatar();
    },[item.id])

    function fetchComment(size) {
        GetHttp(`/bid/${item.id}/comment`, { sort: 'date,DESC',size:size })
            .then(e => {
                if (e.ok) {
                    e.json().then(json => setComments(json));
                }else{
                    e.json().then(json=>setMessage(json.message));
                }
            })
    }

    
    const nav = useNavigate();

    function onNavigateBid(){
        nav("/main/bid",{state:{bid:bid}})
    }
    function onNavigateEmployee(employee){
        nav("/main/employee",{state:{employee:employee}})
    }
    const textArea = useRef(null);

    const addComment = () => {
        const text = textArea.current.value;
        if(text.length > 0) {
            PostHttp(`/bid/${bid.id}/comment`, { message: text })
                .then((res) => {
                    if(res.ok){
                        fetchComment(commentCount);
                    }else{
                        res.json().then(json=>setMessage(json.message));
                    }
                    textArea.current.value="";
                });
        }
    }
    if(bid){
        return(<>
            <div className='p-3 bg-white my-2'>
                <div>
                    <div className='my-2'>
                        <Image src={ownerImg} width={avatarWidth} roundedCircle /> <span className='text-dark me-5' onClick={()=>onNavigateEmployee(bid.owner)} style={{cursor:'pointer'}}> {bid.owner.firstName + " "+bid.owner.lastName}</span><span className='text-dark'>   {new Date(bid.date*1000).toLocaleString()}</span></div>
                    <div className='p-1 fs-5 ' onClick={()=>onNavigateBid()} style={{cursor:'pointer'}}>
                        <span className='text-dark'>Заявка: </span><span className='text-primary mx-3  '>{bid.header}</span>
                        <div className='bg-ligth text-muted my-2' style={{fontSize:"16px"}} >{(bidStausResolver(bid.status))}</div>
                    </div>
                    
                    <div style={{cursor:'pointer'}} className='text-center fw-bold my-3 p-1 text-primary' data-bs-toggle="collapse" data-bs-target={`#collapseText_${bid.id}`}>Показать текст</div>
                    {bid.enableShadowRoot ?
                        <div id={`collapseText_${bid.id}`} className='collapse p-2 m-2 border-bottom'>
                            <root.div>
                                <div dangerouslySetInnerHTML={{ __html: bid.text }} className=' m-2 p-3 border-top border-bottom'></div>
                            </root.div>
                        </div> 
                    : 
                        <div id={`collapseText_${bid.id}`} className='collapse  p-2 m-2 border-bottom'>                    
                            <div dangerouslySetInnerHTML={{ __html: bid.text }} className=' m-2 p-3 border-top border-bottom'></div>                    
                        </div> 
                    }
                    <div className='ms-5 mt-2 w-75'>
                        {comments? comments.content.sort((e,v)=>e.date-v.date).map((e,v)=>{return( <Comment comment={e} key={v}/>)}):  <div>Загрузка</div>}
                    </div>
                    <div className='p-1 text-muted ms-5'>Быстрый комментарий</div>
                    <div className='d-flex align-items-center mt-3 ms-5' style={{maxWidth:"50%"}}>
                        <div className='w-100'><FormControl as="textarea" ref={textArea}/></div>
                        <div className='mx-2 ' style={{cursor:'pointer'}}>
                            <Button size="sm" variant="outline-primary" onClick={addComment}>Отправить</Button>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }else {
        return(<Loader />)
    }
}

function Comment({ comment }) {
    const nav = useNavigate()
    function onNavigateEmployee(employee){
        nav("/main/employee",{state:{employee:employee}})
    }

    function fetchAvatar(uuid){
    
        if(uuid !=="/default-avatar.png" && uuid != null){
                GetHttp("/file/uuid/" + uuid.split(".")[0])
                .then(e => {
                    if (e.ok) {
                        e.blob().then(b =>  {
                            setImg(URL.createObjectURL(b));
                        });
                    }
                })
           
        }else {
            setImg("/default-avatar.png");
        }
    
        
    }
    const [img,setImg] = useState();
    useEffect(()=>fetchAvatar(comment.owner.avatar),[comment.id])

    return (<>
        <div className='p-2 my-1  d-flex'  >
            <div className=' p-2 border rounded'>
                <div className='d-flex align-items-center'>
                    <div><Image src={img} width={avatarWidth} roundedCircle/></div>
                    <div className='mx-2' onClick={()=>onNavigateEmployee(comment.owner)} style={{cursor:"pointer"}}>{comment.owner.firstName + " " + comment.owner.lastName}</div>
                    <div>{new Date(comment.date*1000).toLocaleString()} </div>
                </div>
                <div dangerouslySetInnerHTML={{ __html: comment.message }} className='m-2 p-2 text-dark'></div>
            </div>
        </div>
    </>)
}