import React, { useEffect, useState} from 'react';
import { MenuLayout } from './layout';
import { useLocation } from 'react-router-dom';
import { Form, Row,Col ,Image, Container,DropdownButton, Dropdown} from 'react-bootstrap';
import { GetHttp, PutHttp } from '../assets/connector';
import Loader from './spinner';

export function Employee(){
    
    const location = useLocation();
    const [employee,setEmployee] = useState();
    const [departments,setDepartments] = useState([]);

    function fetchUser(id){
        GetHttp(`/employee/${id}`)
        .then(e=>{
            if(e.ok){
                e.json().then(json=>{
                    setEmployee(json);
                    fetchAvatar(json);
                })
                
            }
        })
    }

    const [image,setImage] = useState();
    const [message,setMessage] = useState();

    function fetchAvatar(employee){
        if(employee.avatar!=null && employee.avatar !=="/default-avatar.png"){
        GetHttp("/file/uuid/" + employee.avatar.split(".")[0]).then(e => {
            if (e.ok) {
                e.blob().then(b => setImage(URL.createObjectURL(b)));
            }
        })
        }else {
            setImage("/default-avatar.png")
        }
    }

    async function fetchDepartmens() {
        await GetHttp("/department").then((d) => {
            if (d.ok) {
                d.json().then(j => setDepartments(j))

            }
        });
    }
    useEffect(()=>{
        fetchDepartmens().then(()=>{
            fetchUser(location.state.employee.id)
        })
        
    },[])
   
    const onChaneDepartment = (event) => {
        PutHttp(`/employee/${location.state.employee.id}`,{department:{id:event.target.value},status:employee.status})
        .then((res)=>{
            if(res.ok){
                fetchUser(location.state.employee.id);
            }else {
                res.json().then(json=>setMessage(json.message));
            }
        });
    }

    function onChaneStatus(status) {
        PutHttp(`/employee/${location.state.employee.id}`,{department:{id:employee.department.id},status:status})
        .then((res)=>{
            if(res.ok){
                fetchUser(location.state.employee.id);
            }else {
                res.json().then(json=>setMessage(json.message));
            }
        });
    }
   
    return(<>
        <MenuLayout message={message}>
            {employee===undefined? <Loader /> :
            <Container className='bg-white p-2' fluid>
            <Row>
                <Col style={{maxWidth:"180px"}}>
                    <div className='my-auto text-center'>
                        <Image roundedCircle width="120"  src={image}></Image>
                    </div>
                    <div className='text-center p-2 m-1'>
                        <DropdownButton size="sm" variant={`${employee.status=='active'?'success':`danger`}`} title={employee.status}>
                            <Dropdown.Item onClick={()=>onChaneStatus("blocked")}>Блокировать</Dropdown.Item>
                            <Dropdown.Item onClick={()=>onChaneStatus("active")}>Разблокировать</Dropdown.Item>
                        </DropdownButton>
                    </div>
                </Col>
                <Col style={{maxWidth:"480px"}}>
                    <Form.Group className="mb-3" >
                        <Form.Label>Email</Form.Label>
                        <div className='fs-5'>{employee.email}</div>
                    </Form.Group>
                    <div className='d-flex'>
                        <Form.Group className="mb-3 me-5" >
                            <Form.Label >Имя</Form.Label>
                            <div className='fs-5'>{employee.firstName}</div>
                        </Form.Group>
                        <Form.Group className="mb-3 ms-5" >
                            <Form.Label >Фамилия</Form.Label>
                            <div className='fs-5'>{employee.lastName}</div>
                        </Form.Group>
                    </div>
                    <Form.Group className="mb-3" >
                        <Form.Label>Группа</Form.Label>
                        <div className='fs-5 '>{employee.role}</div>
                    </Form.Group>
                    <Form.Group className="mb-3" >
                        <Form.Label>Зарегестрирован</Form.Label>
                        <div className='fs-5'>{new Date(employee.regDate*1000).toLocaleString()}</div>
                    </Form.Group>
                    <Form.Group className="mb-3" >
                        <Form.Label>Отдел</Form.Label>
                        <Form.Select onChange={onChaneDepartment} defaultValue={employee.department.id}>
                            {departments.map((dep,i)=><option key={i} value={dep.id}>{dep.name}</option>)}                            
                        </Form.Select>                        
                    </Form.Group>
                </Col>
            </Row>
            
        </Container>   }
        </MenuLayout></>)

}