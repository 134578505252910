
import React, { useEffect, useReducer, useState } from 'react';
import { DeleteHttp, GetHttp, PostHttp, PutHttp } from '../../assets/connector';
import { Customers } from './customers';
import { ContentServiceCRUD } from '../servicecrud';
import { Accounts } from './accounts';
import { KeycloakEvents } from '../keycloak/events';
import {useResponseStatusCheck } from '../../common/common';

export function Endpoint({service,endpointid}){

    const [endpoint,setEndpoint] = useState({httpMethods:[]});
    const checkStatus = useResponseStatusCheck();
    function getEndpoint(id){
        
        GetHttp(`/endpoints/${id}`).then(res=>{
            if(res.ok){
                checkStatus(res.status)
                res.json().then(json=>setEndpoint(json));
            }
        })
        
    } 

    useEffect(()=>getEndpoint(endpointid),[endpointid]);
    switch(endpoint.path){
        case "customers":  return <Customers service={service} endpointid={endpointid}/>
        case "accounts": return <Accounts service={service} endpointid={endpointid}/>
        case "keycloak_events": return <KeycloakEvents service={service} endpointid={endpointid}/>
        default :return  <ContentServiceCRUD service={service} endpointid={endpointid} />
    }

}

