import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import '../assets/css/menu.css'
import { useAuth } from "react-oidc-context";
import {  NavLink } from "react-router-dom";
import { defaultMenu } from "../App";

const btnStyle="w-100 text-decoration-none text-secondary btn btn-link p-2";
const btnStyleActive="w-100 text-decoration-none text-secondary btn btn-link text-light menu-active p-2";
const activeDropdown = "dropdown-active";

 function AccessControlProvider(props){

  

    const auth = useAuth();
    if(props.scope!==undefined){
      let result = false;
      for(let e of props.scope){
        if(auth.user.scopes.includes(e)){
          result = true;
          break;
        }
      }
  
      if(result){
        return props.children
      }else {
        return ''
      }
    }else return props.children
  
  }

export function Menu(){
    const auth = useAuth();
    const [manu,setMenu] = useState([]);

    useEffect(()=>setMenu(defaultMenu),[defaultMenu.length])

    function StyledLink(props){
        const data = props.data;
        return( 
            <NavLink to={props.path} className={({isActive})=>isActive? btnStyleActive:btnStyle} end >
                {data.icon}{data.name}
            </NavLink>);
    }
    
    return (<>
        
        <div className="position-fixed m-0 p-1 h-100 overflow-auto sidebar">
            <div className="" >
                {manu.map((e,i)=>{
                    if(e.isHidden===undefined){
                        if(e.category){
                            return(<div className="text-start text-secondary ms-4  p-1 mt-1 fs-5">{e.name}</div>)
                        }else if(e.children.length ===0){
                            return <AccessControlProvider key={i} scope={e.scope}>
                                <StyledLink data={e} path={e.path}/>
                            </AccessControlProvider>
                        }
                        else {
                            
                            return(
                            <DropdownMenuButton key={i} name={e.name} active={document.location.pathname.includes(e.path)}>
                                {e.children.map((sub,i)=>
                                {
                                    if(sub.isHidden===undefined){
                                        return(
                                            <AccessControlProvider scope={sub.scope} key={i}>
                                                <StyledLink data={sub} path={e.path+sub.path}/>
                                            </AccessControlProvider>
                                        )
                                    }
                                })}
                            </DropdownMenuButton>
                            )
                        }
                }
                })}                       
                <Button variant="link" className="w-100 text-decoration-none text-secondary mt-1" onClick={()=>auth.signoutSilent()}>Выход</Button>          
            </div>
        </div>

        </>)

}


function DropdownMenuButton(props){
    const handleClick = () =>{
        const collapseElementList = document.querySelectorAll('.collapse')
        const collapseList = [...collapseElementList]
        collapseList.forEach(collapseEl => {
            collapseEl.classList.remove("show");
            collapseEl.classList.remove(activeDropdown);
        });
        
    }
    const target = "collapse_"+props.name.replaceAll(' ','_');
    return(<>
        <div className="text-start">
                <Button 
                  className={props.active? btnStyle+" "+activeDropdown: btnStyle}
                  data-bs-toggle="collapse"
                  data-bs-target={"#"+target}
                  aria-expanded="false"
                  type="button"
                  onClick={handleClick}
                  >
                    {props.name}
                <i className="bi bi-caret-down float-end"></i></Button>
                <div className={props.active? "collapse dropdown-container show" :"collapse dropdown-container"} id={target}>
                    {props.children}
                </div>
            </div>
    </>)
}