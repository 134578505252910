import React, { useEffect, useReducer, useState } from 'react';
import { DeleteHttp, GetHttp, PostHttp, PutHttp } from '../../assets/connector';
import {
	Table,
	Row,
	Button,
	InputGroup,
	FormControl,
	Form,
	Modal,
	Col, FormSelect,
	Tab, Tabs, Container,
} from 'react-bootstrap';
import { GetMore, PagenationPanel } from '../tables';
import Loader from '../spinner';



export function Customer({ id, endpoint, onHide }) {

	return (<div>
		<Tabs defaultActiveKey="general" id="noanim-tab" mountOnEnter unmountOnExit>
			<Tab eventKey="general" title="Главная" className='bg-white p-1'>
				<GeneralInformation id={id} endpoint={endpoint} />
			</Tab>
			<Tab eventKey="logging" title="Журнал событий">
				<CustomerLogger id={id} endpoint={endpoint}/>
			</Tab>
			<Tab eventKey="accountlogging" title="События пользователей">
				<AccountLogging id={id} endpoint={endpoint} />
			</Tab>
			<Tab eventKey="account" title="Сотрудники">
				<Members id={id} endpoint={endpoint} />
			</Tab>

		</Tabs>

		<div className="text-center  bg-white p-2">
			<Button id={-1} onClick={onHide} className="mr-1">Закрыть</Button>
		</div>


	</div>)
}


function GeneralInformation({ id, endpoint }) {

	const [customer, setCustomer] = useState();
	const [tariffs, setTariffs] = useState([])

	function fetchCustomer() {
		GetHttp(`/endpoints/${endpoint.remoteService}/request/customer/${id}`)
			.then(res => {
				if (res.ok) {
					res.json().then(json => {
						setCustomer(json);
						setCustomerChanged({ name: json.name, description: json.description })
					})

				}
			})
	}

	function fetchTariff() {
		GetHttp(`/endpoints/${endpoint.remoteService}/request/tariffs`)
			.then(res => {
				if (res.ok) {
					res.json().then(json => {
						setTariffs(json);
					})
				}
			})
	}

	useEffect(() => {
		fetchCustomer();
		fetchTariff();
	}, [])

	const handleOnChangeTarif = (event) => {
		GetHttp(`/endpoints/${endpoint.remoteService}/request/customertariff?customerid=${customer.id}&tariffid=${event.target.value}`)
	}

	const handleUpdateCustomer = (event) => {
		const target = event.target;
		if (target.getAttribute('aria-describedby') === "name") {
			customer.name = target.value;
			setCustomer(customer)
		}
		if (target.getAttribute('aria-describedby') === "description") {
			customer.description = target.value;
			setCustomer(customer);
		}
	}



	const [customerChanged, setCustomerChanged] = useState();

	const onUpdateCustomer = () => {
		PostHttp(`/endpoints/${endpoint.remoteService}/request/updatecustomer`, { name: customerChanged.name, description: customerChanged.description, id: customer.id })
	}

	if (customer !== undefined) {
		return (<>
			<Container fluid>
				<Row >
					<Col sm={5}>
						<InputGroup className="mb-3 mt-2">
							<InputGroup.Text id="name">Наименование</InputGroup.Text>
							<FormControl
								placeholder="Название"
								aria-label="Название"
								aria-describedby="name"
								defaultValue={customer.name}
								type="text"
								onChange={(e) => setCustomerChanged({ name: e.target.value, description: customerChanged.description })}

							/>
						</InputGroup>

						<InputGroup className="mb-3">

							<InputGroup.Text id="name">Токен</InputGroup.Text>

							<FormControl
								placeholder="Токен"
								aria-label="Токен"
								aria-describedby="token"
								defaultValue={customer.token}
								type="text"
								readOnly='true'
							/>
						</InputGroup>

						<InputGroup className="mb-3">

							<InputGroup.Text id="description">Примечание</InputGroup.Text>

							<FormControl
								placeholder="Примечание"
								aria-label="Примечание"
								aria-describedby="description"
								defaultValue={customer.description}
								type="text"
								onChange={(e) => setCustomerChanged({ name: customerChanged.name, description: e.target.value })}

							/>
						</InputGroup>
						<div className='text-center my-2'><Button onClick={onUpdateCustomer}>Обновить</Button></div>
					</Col>
				</Row>
				<div className="d-flex">
					<div className="me-2">
						<InputGroup className="mb-3">
							<InputGroup.Text id="tarif-input">Тариф</InputGroup.Text>
							<FormControl as="select"
								aria-label="Тариф"
								aria-describedby="tarif-input"
								onChange={handleOnChangeTarif}>
								{tariffs.map((a, key) => {
									return (<option
										value={a.index}
										selected={customer.tariff.id === a.id}
										key={key}>{a.name}</option>)
								})}
							</FormControl>
						</InputGroup>
					</div>


				</div>
			</Container>
			<div><TariffInfo customer={customer} onUpdate={() => fetchCustomer()} endpoint={endpoint}></TariffInfo>
			</div>
		</>)
	} else {
		return (<div>Ошибка</div>)
	}


}

export function CustomerContact(props) {
	const [contacts, setContacts] = useState({ content: [] })
	const [showContactViwer, setShowContactViwer] = useState(false)
	const [showContactAdd, setShowContactAdd] = useState(false)
	const [value, setValue] = useState("");
	const [newContact, setNewConstact] = useState({ name: '', value: '' })
	const [error, setError] = useState("");

	useEffect(() => {


	}, [])

	function onViewValue(val) {
		setShowContactViwer(true);
		setValue(val)
	}
	function onAddNewContact() {
		if (newContact.name.length > 0 && newContact.value.length > 0) {

		} else {
			setError("Оба поля должны быть заполнены");
		}
	}
	function onDeleteContact() {



	}

	const modalAdd = <>
		<Modal show={showContactAdd} onHide={() => setShowContactAdd(false)}>
			<Modal.Header closeButton>
				<Modal.Title>Добавить</Modal.Title>
			</Modal.Header>
			<Modal.Body >
				{error !== "" ? <div className="alert alert-danger">{error}</div> : ''}
				<Form.Group className="mb-3" >
					<Form.Label>Название</Form.Label>
					<Form.Control type="text" value={newContact.name} onInput={(event) => setNewConstact({ name: event.target.value, value: newContact.value })} />
				</Form.Group>
				<Form.Group className="mb-3" >
					<Form.Label>Значение</Form.Label>
					<Form.Control type="text" value={newContact.value} onInput={(event) => setNewConstact({ name: newContact.name, value: event.target.value })} />
				</Form.Group>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={() => setShowContactAdd(false)}>
					Закрыть
				</Button>
				<Button variant="danger" onClick={() => onAddNewContact()}>
					Сохранить
				</Button>
			</Modal.Footer>
		</Modal>
	</>

	const modal = <>
		<Modal show={showContactViwer} onHide={() => setShowContactViwer(false)}>
			<Modal.Header closeButton>
				<Modal.Title>{value.name}</Modal.Title>
			</Modal.Header>
			<Modal.Body className="text-center">{value.value}</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={() => setShowContactViwer(false)}>
					Закрыть
				</Button>
				<Button variant="danger" onClick={() => onDeleteContact()}>
					Удалить
				</Button>
			</Modal.Footer>
		</Modal>
	</>
	if (contacts.content.length === 0) {
		return (<>
			{modal}
			{modalAdd}
			<div className="text-center"><Button onClick={() => setShowContactAdd(true)}>Добавить поле</Button></div>
		</>)
	} else {
		<>
			{modal}
			{modalAdd}
			<div className=" p-1 w-100">
				<div className="d-flex flex-wrap">
					{contacts.content.map((v, k) => {

						return (
							<div className="m-2">
								<Button className="ms-auto" variant="outline-dark" onClick={() => onViewValue(v)}>{v.name}: {v.value}</Button>
							</div>
						)

					})}
				</div>
				<div className="text-center"><Button onClick={() => setShowContactAdd(true)}>Добавить поле</Button></div>
			</div>
		</>
	}


}

export function TariffInfo({ customer, endpoint, onUpdate }) {

	const [tariff, setTariff] = useState(customer.tariff);

	function fetch() {

	}

	useEffect(() => {
		fetch()
	}, [])


	function handleBlock() {

		GetHttp(`/endpoints/${endpoint.remoteService}/request/disablecustomer/${customer.id}`)
			.then(res => {
				if (res.ok) {
					onUpdate();
				}
			})
	}

	return (<>
		<div className="d-block p-2  ">
			{customer !== undefined ? <>
				<div>
					{customer.customerState === "normal" ?
						<div className="d-flex align-items-end text-dark">
							<div className="p-2 text-success">
								Состояние: Не заблокирован
							</div>
							<div>
								<Button variant="danger" className="ms-3" onClick={() => handleBlock()}>Блокировать</Button>
							</div>
						</div>
						:
						<div className="d-flex">
							<div>
								<div className="text-danger p-2">
									Состояние: Заблокирован
								</div>
							</div>
							<div>
								<Button variant="success" className="ms-3" onClick={() => handleBlock()} >Разблокировать</Button>
							</div>
						</div>
					}
					<div className="my-2 text-dark">
						<h2 className="mt-2">Баланс: {customer.balance} руб. Стоимость тарифа: {tariff.price} руб.</h2>
					</div>
					<div className="p-2  text-dark">
						Использовано хранилище : {(customer.repositoryUtilizationBytes / 1024 / 1024 / 1024).toFixed(3)}
						/ {tariff.repositoryLimit / 1024 / 1024 / 1024} Гб

					</div>
				</div>


				<div>
					<Table size="sm" striped bordered hover>
						<thead>
							<tr>
								<th>#</th>
								<th>Параметр </th>
								<th>Значение </th>
							</tr>
						</thead>
						<tbody>

							<tr>
								<td>1</td>
								<td>Аккаунты</td>
								<td>{customer.totalAccounts + " из " + tariff.accountLimit}</td>
							</tr>
							<tr>
								<td>2</td>
								<td>Инструмент</td>
								<td>{customer.totalInstrumentCount + " из " + tariff.instrumentLimit}</td>
							</tr>
							<tr>
								<td>3</td>
								<td>Склады</td>
								<td>{customer.totalIC + " из " + tariff.warehousesLimit}</td>
							</tr>

						</tbody>

					</Table>
				</div> </> : ''}
		</div>
	</>)
}

function AccountLogging({ id, endpoint }) {
	const [accountLog, setAccountLog] = useState({ content: [], pageable: { pageNumber: 0 } })
	useEffect(() => { fetch(0); }, [])

	function fetch(page) {
		GetHttp(`/endpoints/${endpoint.remoteService}/request/accountlog?customer=${id}&page=${page}&sort=created,DESC`)
			.then(res => {
				if (res.ok) {
					res.json().then(json => {
						setAccountLog(json);
					})

				}
			})
	}
	const fields = [{ name: "id", sort: "id" }, { name: "Сообщение", sort: "message" }, { name: "Аккаунт", sort: "account" }, { name: "targetid", sort: "targetid" }, { name: "Дата", sort: "created" }];
	return (<>

		<Table >
			<thead>
				<tr>
					{fields.map(e => <th>{e.name}</th>)}
				</tr>
			</thead>
			<tbody>
				{accountLog.content.map((v, k) => {
					return <>
						<tr>
							<td>{k + 1}</td>
							<td>{v.message}</td>
							<td>{v.account}</td>
							<td>{v.targetid}</td>
							<td>{new Date(v.created*1000).toLocaleString("ru-Ru")}</td>
						</tr>
					</>
				})}
			</tbody>
		</Table>
		<PagenationPanel page={accountLog} nextPage={(page) => fetch(page)} />
	</>)


}

function Members({ id, endpoint }) {
	const [members, setMembers] = useState({ content: [] })
	const [inviteEmail, setInvateEmail] = useState({ defaultCustomer: id, email: '' });
	const pageSize = 10;
	const [permissions, setPermissions] = useState([])
	useEffect(() => { fetch(pageSize); }, [])

	const onInvite = () => {
		PostHttp(`/endpoints/${endpoint.remoteService}/request/invite`, inviteEmail)
			.then(() => fetch(pageSize))
	}

	function onRemove(user) {
		DeleteHttp(`/endpoints/${endpoint.remoteService}/request/invite?customer=${id}&user=${user}`)
			.then(() => fetch(pageSize));
	}
	
	function fetch(size) {
		GetHttp(`/endpoints/${endpoint.remoteService}/request/permission`)
			.then(res => {
				if (res.ok) {
					res.json().then(json => setPermissions(json));
					GetHttp(`/endpoints/${endpoint.remoteService}/request/members?customerid=${id}&page=0&size=${size}`)
						.then(res1 => {
							if (res1.ok) {
								res1.json().then(json1 => {
									setMembers(json1);
								})

							}
						})
				}
			})

	}
	function onSetpermission({ permissionName,account }) {
		PostHttp(`/endpoints/${endpoint.remoteService}/request/setpermission`,{customer:id,id:account,permission:permissionName})
	}

	const fields = ["id", "Имя", "Фамилия", "Телефон", "Дата", "email", "Права", "Зарегестрирован", "x"];



	return (<>
		<Row className='align-items-center bg-white m-0'>
			<Col sm="3">
				<FormControl className='m-2'
					placeholder="email нового сотрудника"
					type="email"
					onChange={(e) => setInvateEmail({ defaultCustomer: id, email: e.target.value })}
				/>

			</Col>
			<Col>
				<Button className="mb-1" variant="success" size="sm" onClick={onInvite}>Пригласить</Button>
			</Col>
		</Row>
		<Row className="m-0 bg-white">
			<Table >
				<thead>
					<tr>
						{fields.map(e => <th>{e}</th>)}
					</tr>
				</thead>
				<tbody>
					{members.content.map((v, k) => {
						return <>
							<tr>
								<td>{k + 1}</td>
								<td>{v.firstName}</td>
								<td>{v.secondName}</td>
								<td>{v.phone}</td>
								<td>{new Date(v.registrationDate).toLocaleString("ru-Ru")}</td>
								<td>{v.email}</td>
								<td>
									<FormSelect onChange={(e)=>onSetpermission({account:v.id,permissionName:e.target.value})}>
										{permissions.map(p=>{
											return(<option value={p.permissionName} selected={p.permissionName===v.permission.permissionName}>{p.permissionName}</option>)
										})}
									</FormSelect>

								</td>
								<td>{v.registred ? "Да" : "Нет"}</td>
								<td><i className="bi bi-trash text-danger" onClick={() => onRemove(v.id)}></i></td>
							</tr>
						</>
					})}
				</tbody>
			</Table>
			<GetMore pageable={members} onClick={() => { fetch(members.size + pageSize) }} />
		</Row>
	</>)


}

function CustomerLogger({id,endpoint}){


	const fields = [{ name: "id", sort: "id" }, { name: "Сообщение", sort: "message" }, { name: "Аккаунт", sort: "account" }, { name: "targetid", sort: "targetid" }, { name: "Дата", sort: "created" }];

	const [log,setLog]= useState();

	function fetch(page){
		GetHttp(`/endpoints/${endpoint.remoteService}/request/customerlog?customer=${id}&page=${page}&sort=time,DESC`)
			.then(res => {
				if (res.ok) {
					res.json().then(json => {
						setLog(json);
					})

				}
			})
	}

	useEffect(()=>fetch(0),[])
	if(log){
	return(<>
		<Table >
			<thead>
				<tr>
					{fields.map(e => <th>{e.name}</th>)}
				</tr>
			</thead>
			<tbody>
				{log.content.map((v, k) => {
					return <>
						<tr>
							<td>{k + 1}</td>
							<td>{v.message}</td>
							<td>{v.owner? v.owner.firstName+" "+ v.owner.secondName: " no"}</td>
							<td>{v.logType}</td>
							<td>{new Date(v.time).toLocaleString()}</td>
						</tr>
					</>
				})}
			</tbody>
		</Table>
		<PagenationPanel page={log} nextPage={(page) => fetch(page)} />
	</>)
	}else {
		return(<Loader />)
	}
}