import React, { useEffect,  useState } from 'react';
import {  GetHttp,  } from '../../assets/connector';
import { Table,  } from 'react-bootstrap';
import { PagenationPanel, SearchForm } from '../../common/tables';
import { Account } from '../../common/skladsystem/account';

export function Accounts({service,endpointid}){

    const [accounts,setAccounts] = useState({content:[],pageable:{}})
    const [showAccount,setShowAccount] = useState();
    function fetch(page) {
        GetHttp(`/endpoints/${service}/request/accounts?page=${page}&size=10`)
            .then(res => {
                if (res.ok) {
                    res.json().then(json => {
                        setAccounts(json);
                    })

                }
            })
        
    }
    useEffect(()=>fetch(0),[])
    if(showAccount){    
        return (<Account  service={service} accountid={showAccount} onHide={()=>setShowAccount()}/>)
    }else {
    
        function escapeNull(val){
            if(val === null) {return "Не задано"};
            return val
        }
    
    return(<>
        <div className='bg-white pt-2' >
        <div className='ms-auto col-3'><SearchForm  onSearch={(s)=>{}}/></div>
        <Table className="mt-1" >
            <thead>
            <tr>
                <th>id</th>
                <th>Имя</th>
                <th>Email</th>
                <th>Телефон</th>
                <th>Регистрация</th>
                <th>Состояние</th>
                
            </tr>     
            </thead>
            <tbody>
                {accounts.content.map(e => {
                    let name;
                    
                   
                    return (<tr>
                        <td>{e.id}</td>
                        <td className='text-primary' style={{cursor:'pointer'}} onClick={()=>setShowAccount(e.email)}>{escapeNull(e.surnameName) +" "+ escapeNull(e.firstName)}</td>
                        <td>{e.email}</td>
                        <td>{e.phone}</td>
                        <td>{new Date(e.registrationDate).toLocaleString()}</td>
                        <td>{e.enabled?"Активен":"Не активен"}/{e.registred?"Зареган":"Не зареган"}</td>
                    </tr>)
                }
                )}
            </tbody>
        </Table>
        <div className='p-2'>
            <PagenationPanel page={accounts} nextPage={(p)=>fetch(p)}/>
        </div>
        </div></>)
        }
}