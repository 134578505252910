import React from 'react';
import { TopBar } from '../common/layout';


export function Company(){

    return(<>
    <TopBar disabled title=""/>
    <div className="position-relative bg-white container" style={{top:"51px"}}>
        
            <div className='text-center p-2 m-1'>Нет доступных компаний</div>
        
    </div>
        </>
    )
}