import React, {useEffect, useState} from 'react';
import { Container } from 'react-bootstrap';

export default function Loader(){

    return(
    <div className='d-flex justify-content-center vh-100 align-items-center flex-column'>
        <span className='loader'></span>
        <div>Загружаем...</div>
    </div>
    )

}