import React  from "react";
import { Link, useLocation } from "react-router-dom";
import { MenuLayout } from "./layout";

export function ErrorPage(){


    return(<>
        <div className="p-5 text-center">
            <div className="fs-5">Уппс, что-то пошло не так!</div>
            <div className="text-center fs-8">Возможно ошибка в адресе. Вернутся <Link to="/">на главную</Link></div>
        </div>
        </>
    )
}

export function ServiceNotConfigured(){


    return(<>
        <div className="p-5 text-center">
            <div className="fs-5">Уппс, что-то пошло не так!</div>
            <div className="text-center fs-8">Этот сервис не корректно настроен <Link to="/">на главную</Link></div>
        </div>
        </>
    )
}

export function Error(){
    const location = useLocation();

    return(<>
        <MenuLayout>            
            <div className='d-flex justify-content-center vh-100 align-items-center flex-column'>
            <div className="fs-5">{location.state.message}</div>
            <div className="text-center fs-8">Произошла ошибка. Вернутся <Link to="/">на главную</Link></div>
            </div>
        </MenuLayout>
    </>)
}

export function AccessForbidden(){

    return(<MenuLayout>            
        <div className='d-flex justify-content-center vh-100 align-items-center flex-column'>
        <div className="fs-5">Уведомление</div>
        <div className="text-center fs-8">Недостаточно прав доступа. Вернутся <Link to="/">на главную</Link></div>
        </div>
    </MenuLayout>)
}