import React, { useEffect, useState } from 'react';
import { MenuLayout } from '../common/layout';
import { Container, Table, Col, Button } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { GetHttp, PostHttp } from '../assets/connector';
import Loader from './spinner';

export function Mail() {


    const location = useLocation();

    const [messages, setMessages] = useState({content:[]});
    const [error, setError] = useState();
    const [loading, setLoading] = useState(true);
    const [page,setPage] = useState(0);

    function nextPage(){
        setLoading(true);
        fetchMessages(page+1);
    }
    function previosPage(){
        setLoading(true);
        fetchMessages(page-1);
    }
    function fetchMessages(page) {
        GetHttp(`/channel/mail/${location.state.id}`,{page:page})
            .then((res) => {
                if (res.ok) {
                    res.json().then(json => setMessages(json));
                } else {
                    res.json().then(json => setError(json.message));
                }
                setPage(page);
                setLoading(false);
                
            })
    }

    function addBid(messageid){
        PostHttp(`/bid/channel/${location.state.id}`,{messageNumber:messageid})
        .then((res)=>{
            if(!res.ok){
                res.json().then(json=>setError(json.message));
            }
        });
    }

    useEffect(() => {
        fetchMessages(0);
    }, [])
    if (loading) {
        return <MenuLayout message={error}><Container fluid ><Loader /></Container></MenuLayout>
    } else {
            return <MenuLayout message={error}>
                <Container fluid >
                    <Col className='bg-white p-3'>
                        <div className='d-flex p-2'>
                            <div><Button variant='link' onClick={()=>previosPage()} disabled={messages.first}>Предыдущая</Button></div>
                            <div className='h5 ms-auto'>{`${location.state.name} Сообщений: ${messages.totalElements}`}</div>
                            <div className="ms-auto"><Button variant='link' onClick={()=>nextPage()}  disabled={messages.last}>Слудующая</Button></div>
                        </div>
                        <Table  hover>
                            <tbody>
                            {messages.content.map((e, i) => 
                                <tr key={i}>
                                    <td>{e.from}</td>
                                    <td>{e.subject}</td>
                                    <td>{new Date(e.receiveDate*1000).toLocaleString()}</td>
                                    <td><Button size="sm" disabled={e.bidExist} onClick={()=>addBid(e.messageNumber)}>Заявка</Button></td>
                                </tr>
                            )}
                            </tbody>
                        </Table>
                        <div></div>
                    </Col>
                </Container></MenuLayout>

        
    }

}