import React from 'react';
import { Button } from 'react-bootstrap';
import { useAuth } from 'react-oidc-context';

export function Unauth(){

    const auth = useAuth();

    return(<>
        <div className='vh-100 w-100 d-flex align-items-center justify-content-center'>
                <Button  onClick={()=>auth.signinRedirect()} className=''>Авторизоватся</Button>
        </div>
    </>)
}