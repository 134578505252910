import React, { useEffect, useReducer, useState } from 'react';
import { MenuLayout } from '../common/layout';
import { Container, Form, Row, Col, Button ,Alert} from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { GetHttp, PutHttp } from '../assets/connector';
import Loader from './spinner';
import {useResponseStatusCheck } from '../common/common';


export function Channel({ menu }) {
    const location = useLocation();
    const checkStatus = useResponseStatusCheck();
    
    function fetch() {
        GetHttp(`/channel/${location.state.id}`)
            .then((res) => {
                checkStatus(res.status);
                if(res.ok){
                res.json().then(json=>setConfig(json))
                }
            })
    }
    useEffect(() => { fetch() }, [])
    const [config, setConfig] = useState()
    if (config) {
        switch (config.channelType) {
            case 'email': return <MenuLayout menu={menu}><EmailChannel channel={config} /></MenuLayout>
            default: return <MenuLayout menu={menu}><Othen channel={config} /></MenuLayout>
        }
        
    } else {
        return <Loader />
    }

}

function Othen({ channel }) {
    return (<>
        <Container fluid >
            <Row>
                <div>В разработке</div>
            </Row>
        </Container>
    </>)

}

function EmailChannel({ channel }) {

    const [message,setMessage] = useState();
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    function reducer(act, target) {
        forceUpdate()
        if(target.value.length === 0) 
            {setMessage('Поле не можетбыть пустым')}
        else{
            setMessage()
        }
        act[target.name] = target.value;
        return act;
    }
    const [newChannelConfig, setNewChannelConfig] = useReducer(reducer, channel)

    function saveChannel() {
        
            PutHttp("/channel", newChannelConfig)
            .then(res=>{
                if(!res.ok){
                    res.json().then(json=>setMessage(json.message))
                }else {
                    setMessage();
                }
            })
        
    }
    const nav = useNavigate()

    const onShowMessage=()=>nav("/ch/mail",{state:{id:channel.id,name:channel.name}});

    const [enablePasswordChange,setEnablePasswordChage] = useState(false);
    const [enableSmtpPasswordChange,setEnableSmtpPasswordChage] = useState(false);


    const sendTest=()=>{
        GetHttp(`/channel/${channel.id}/smtptest`)
        .then((res)=>{
            if(res.ok){
                {setMessage("Отправленно тест сообщение")}
            }else {
                res.json().then(json=>{
                    {setMessage("Ошибка "+ json.message)}
                })
                
            }
        })
        ;
    }

    return (<>
        <Container fluid >
            <Row>
            {message?  <Alert variant='danger'>{message}</Alert> : ''}
            <Col className='bg-white p-3' sm="5">
            <Form.Check type="checkbox" label="Включить (Если возникают ошибки подключения, будет автоматически отключенно)" defaultChecked={newChannelConfig.enabled} onChange={(event)=>setNewChannelConfig({name: 'enabled',value:event.target.checked})}/>
                    <Form.Group className="mb-3" controlId="">                    
                </Form.Group>
                <div className='text-center'><h4>Настройки:{channel.name+" "+channel.channelType}</h4></div>
                <div className='text-center h5'>Сервер входящих сообщений</div>
                <Form.Group className="mb-3" controlId="host">
                    <Form.Label>Название</Form.Label>
                    <Form.Control type="text" placeholder="" defaultValue={newChannelConfig.name} onChange={(e) => setNewChannelConfig({ name: 'name', value: e.target.value })} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Адрес почты</Form.Label>
                    <Form.Control type="email" placeholder="Enter email" defaultValue={newChannelConfig.description} onChange={(e) => setNewChannelConfig({ name: 'from', value: e.target.value })} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="host">
                    <Form.Label>IMAP Сервер</Form.Label>
                    <Form.Control type="text" placeholder="" defaultValue={newChannelConfig.host} onChange={(e) => setNewChannelConfig({ name: 'host', value: e.target.value })} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="port">
                    <Form.Label>Порт</Form.Label>
                    <Form.Control type="text" placeholder="" defaultValue={newChannelConfig.port} onChange={(e) => setNewChannelConfig({ name: 'port', value: e.target.value })} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="username">
                    <Form.Label>Имя пользователя</Form.Label>
                    <Form.Control type="text" placeholder="" defaultValue={newChannelConfig.username} onChange={(e) => setNewChannelConfig({ name: 'username', value: e.target.value })} />
                </Form.Group>
                {enablePasswordChange ? 
                <Form.Group className="mb-3" controlId="password" >
                    <Form.Label>Пароль (оставить пустым, если не менять)</Form.Label>
                    <Form.Control type="password" placeholder="" onChange={(e) => setNewChannelConfig({ name: 'password', value: e.target.value })} />
                </Form.Group>:
                <div className='my-2 text-center'> <Button variant='link' onClick={()=>setEnablePasswordChage(true)}>Поменять пароль</Button></div>}
                <div className='d-flex'>
                    <div className='me-2 p-1'>
                    <Form.Check type="checkbox" label="Разрешить SSL" defaultChecked={newChannelConfig.enableSSL} disabled={newChannelConfig.startTLS} onChange={(e)=>setNewChannelConfig({name: 'enableSSL',value:e.target.checked})}/>
                    <Form.Check type="checkbox" label="Start SSL/TLS" defaultChecked={newChannelConfig.startTLS} onChange={(e)=>setNewChannelConfig({name: 'startTLS',value:e.target.checked})}/>
                    </div>
                    <Alert variant='success ms-1' >
                        SSL порт по умолчанию 993.
                        TLS/без шифрования 143.
                    </Alert>
                </div>
                <div className='text-center'>
                    <Button variant="link" onClick={onShowMessage}>Показать сообщения</Button>
                </div>
                

            </Col>
            <Col className='bg-white p-3 ms-2' sm="5">
            <div className=' mt-4 text-center h5'>Сервер исходящих сообщений</div>
                <Form.Group className="my-3" controlId="smtphost">
                    <Form.Label>SMTP Сервер по умолчанию</Form.Label>
                    <Form.Check  type="switch" defaultChecked={newChannelConfig.defaultSmtp} onChange={(e) => setNewChannelConfig({ name: 'defaultSmtp', value: e.target.value==="on" })} />
                </Form.Group>
                <Form.Group className="my-3" controlId="smtphost">
                    <Form.Label>SMTP Сервер</Form.Label>
                    <Form.Control type="text" placeholder="" defaultValue={newChannelConfig.smtpHost} onChange={(e) => setNewChannelConfig({ name: 'smtpHost', value: e.target.value })} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="port">
                    <Form.Label>SMTP Порт</Form.Label>
                    <Form.Control type="text" placeholder="" defaultValue={newChannelConfig.smtpPort} onChange={(e) => setNewChannelConfig({ name: 'smtpPort', value: e.target.value })} />
                </Form.Group>
                <Form.Group className="my-3" controlId="smtplogin">
                    <Form.Label>SMTP логин</Form.Label>
                    <Form.Control type="text" placeholder="" defaultValue={newChannelConfig.smtpLogin} onChange={(e) => setNewChannelConfig({ name: 'smtpLogin', value: e.target.value })} />
                </Form.Group>
                {enableSmtpPasswordChange ?
                <Form.Group className="my-3" controlId="smtppass">
                    <Form.Label>SMTP пароль</Form.Label>
                    <Form.Control type="password" placeholder=""  onChange={(e) => setNewChannelConfig({ name: 'smtpPassword', value: e.target.value })} />
                </Form.Group>:
                <div className='my-2 text-center'> <Button variant='link' onClick={()=>setEnableSmtpPasswordChage(true)}>Поменять пароль</Button></div>}
                <div className='my-2 text-center'> 
                    <Button variant='link' onClick={sendTest}>Отправить тестовое сообщение </Button>
                </div>
            </Col>
            </Row>
            
            <Row >
                <Col sm={10} className="bg-white p-3 mt-2 text-center">
                    <Button onClick={() => saveChannel()}>Применить</Button>
                </Col>
                </Row>
                
        </Container>
    </>)
}