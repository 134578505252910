import React, { useContext, useEffect, useState } from "react";

import { Menu } from '../common/menu';
import { useAuth } from "react-oidc-context";
import { Button } from 'react-bootstrap';
import { Container, Image ,Alert} from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { UserContex } from "../App";

export function MenuLayout({ children,message }) {

  const [user] = useContext(UserContex);

  return (
    <div className="">
      {/* side bar */}
      <TopBar user={user} title={user.department.name} />
      <Menu />
      <div className='content'>
        <Container fluid className='p-2 '>
        <Alert className="col-sm-10" show={message!=undefined} variant='danger'>{message}</Alert>
          {children}
        </Container >
      </div>
    </div>

  );


}

export function TopBar({ user, title, disabled }) {

  const nav = useNavigate();
  const onUserClick = () => {
    if (!disabled) {
      nav("/userinfo");
    }
  }

  return (<div className="bg-light top-0 position-fixed w-100 z-3 d-flex" style={{ height: "49px" }}>
    <div className="text-bg-light  p-1 fs-4">{title}</div>
    <div className="text-bg-light  p-1 fs-4 ms-auto">
      {user.role.includes('admin') ? <Button variant="link" onClick={() => nav('/manage/main')}>Панель управления</Button> : ''}
    </div>
    <div className=" p-1 me-2 text-secondary fs-4 text-bg-light ">
      <div className="logo">
        <Image width={35} src="/default-avatar.png" className="me-3 rounded-circle border " />
        <Button variant="link text-decoration-none p-0 fs-5 text-secondary" onClick={onUserClick}>{user.email}</Button>
      </div>
    </div>
  </div>)
}