import React from 'react';
import {Form, Modal } from 'react-bootstrap';

export function FileuploadPopup({ show, onChange, onHide }) {

    return (<Modal show={show} backdrop={true} onHide={onHide}>
        <Modal.Body>
            <Form.Control name="file" type="file" onChange={onChange} accept='image/png, image/jpeg' />
        </Modal.Body>
    </Modal>)
}

