import React, { useEffect, useReducer, useState } from 'react';
import { Button ,Col,Form, Image, Row,Table} from 'react-bootstrap';
import { DeleteHttp, GetHttp, PostHttp, PutHttp } from '../../assets/connector';
import Loader from '../spinner';
import image from "../../assets/images/default-avatar.png";
export function Account({service,accountid,onHide}){

    const [account,setAccount] = useState();

    function fetch() {
        GetHttp(`/endpoints/${service}/request/account?email=${accountid}`)
            .then(res => {
                if (res.ok) {
                    res.json().then(json => {
                        setAccount(json);
                    })

                }
            })
        
    }
    const [sendActivateOK,setSendActivateOK] = useState(false);
    
    const onSendActive=()=>{

        setSendActivateOK(true);
    }

    const onBlock=()=>{

    }

    useEffect(()=>fetch(),[]);
    if(account){
        
    return(<Row className='bg-white m-0'>
            <Col sm={2}>
				<div className="p-1" >
                    {account.images.length>0 ? 
					<Image width={240} src={'data:image/png;base64,'+account.images[0].data} /> 
                    : <Image width={240} src={image} />  }
				</div>
			</Col>	
            <Col>
        	<div className="d-flex  p-1 m-1">
					<Form.Group className="m-3">
					<Form.Label>Имя пользователя</Form.Label>
					<Form.Control readonly={true} type="text"  value={account !== undefined ?  account.username : ""}/>
					</Form.Group>

					<Form.Group className="m-3">
					<Form.Label>Фамилия</Form.Label>
					<Form.Control readonly={true} type="text"  value={account !== undefined ?  account.surnameName : ""}/>
					</Form.Group>

					<Form.Group className="m-3">
					<Form.Label>Имя</Form.Label>
					<Form.Control readonly={true} type="text"  value={account !== undefined ?  account.firstName : ""}/>
					</Form.Group>

					<Form.Group className="m-3">
					<Form.Label>Отчество</Form.Label>
					<Form.Control readonly={true} type="text"  value={account !== undefined ?  account.secondName : ""}/>
					</Form.Group>
				</div>
                <div className="d-flex  p-1 m-1">
						<Form.Group className="m-3">
						<Form.Label>Телефон</Form.Label>
						<Form.Control readonly={true} type="text"  value={account !== undefined ?  account.phone : ""}/>
						</Form.Group>

						<Form.Group className="m-3">
						<Form.Label>Email</Form.Label>
						<Form.Control readonly={true} type="text"  value={account !== undefined ?  account.email : ""}/>
						</Form.Group>

						<Form.Group className="m-3">
						<Form.Label>Дата регистрации</Form.Label>
						<Form.Control type="date"  
							disabled={true}
							value={account !== undefined ?  new Date(account.registrationDate).toISOString().substring(0, 10) : ""}/>
						</Form.Group>

						<Form.Group className="m-3">
						<Form.Label>Код регистрации</Form.Label>
						<Form.Control type="text" disabled value={account !== undefined ?  account.registrationCode : ""}/>
						</Form.Group>

					</div>
                    <div className="d-flex  p-2 m-2 ">
                        
						<Form.Check
							inline
							label="Активен"
							type="checkbox"
							checked={account.enabled}						
						/>

						<Form.Check
							inline
							label="Зарегестрирован"
							type="checkbox"
							checked={account.registred}						
						/>
						<div className="d-flex">
							{account.registred ? '' 
							: <Button  size="sm" variant="success" onClick={()=> onSendActive()} disabled={sendActivateOK} >
								{sendActivateOK ?'Письмо отправленно':'Отправить письмо регистрации'} </Button> }
							<Button className='mx-2' size="sm" variant="danger" onClick={onBlock}>Блокировать</Button>
						</div>	
					</div>
                        <Customers service={service} account={account}/>
                    </Col>
        <div className='text-center p-2'>
            <Button onClick={onHide}>Закрыть</Button>
        </div>
        </Row>)
    }else {
        return (<Loader />)
    }
}

function Customers({service,account}){
    const [customers,setCustomers] = useState([]);

    function fetch() {
        GetHttp(`/endpoints/${service}/request/wherework?account=${account.id}`)
            .then(res => {
                if (res.ok) {
                    res.json().then(json => {
                        setCustomers(json);
                    })

                }
            })
        
    }

    useEffect(()=>fetch(),[])
    return(<>
        	<Table striped bordered hover>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Название</th>
                        <th>Примечание</th>
                        <th>Активность</th>
                        <th>По молчанию</th>
                    </tr>
                </thead>
                <tbody>
                    {customers.map((k, v) => {
                        
                        return (
                            <tr>
                                <td>{v + 1}</td>
                                <td>{k.name}</td>
                                <td>{k.description}</td>
								<td>{k.customerState == 'normal' ? "Активен" : "Заблокирован"}</td>
                                <td>{k.id === account.defaultCustomer? "Да":"Нет"}</td>
                            </tr>

                        )
                    })}
                </tbody>
            </Table>

    </>)
}