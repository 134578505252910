import React, { useEffect, useState, useRef, useContext, useReducer } from 'react';
import { MenuLayout } from './layout';
import { Button, Container, Col, Row, Form, Image, FormControl, Modal, Table, Alert, ModalBody, ModalFooter, FormCheck } from 'react-bootstrap';
import { TextEditor, TextEditorSimple } from './text';
import { useLocation, useNavigate } from 'react-router-dom';
import { DeleteHttp, GetHttp, PostHttp, PutHttp, PostHttpMultipart } from '../assets/connector';
import Loader from './spinner';
import { useResponseStatusCheck } from './common';
import { UserContex } from '../App';
import root from 'react-shadow'
import { GetMore } from './tables';


export function bidStausResolver(status){
    switch(status){
        case 'newbid': return "Новая";
        case 'workprogress': return "В работе";
        case 'expired': return "Просрочена";
        case 'complited': return "Выполнена"
    }                               

}

export function ViewBid({ menu }) {
    const location = useLocation();
    const [bid, setBid] = useState();
    const checkStatus = useResponseStatusCheck();
    const [message,setMessage] = useState();
    const nav = useNavigate();

    function fetchBid() {
        GetHttp(`/bid/${location.state.bid.id}`)
            .then((res) => {
                checkStatus(res.status);
                if (res.ok) {
                    res.json().then(json => setBid(json));
                }else {
                    if(res.status==403){nav("/forbidden")}
                }
            })
    }


    useEffect(() => { fetchBid() }, [])


    if (bid) {
        return (<>
            <MenuLayout message={message}>
                <Row>
                    <Col sm={10} className='pe-1'>
                        <BidDetails bid={bid} update={() => fetchBid()} setMessage={e=>setMessage(e)}/>
                        <BidHistory bid={bid} />
                        <CheckList bid={bid} setMessage={e=>setMessage(e)}/>
                        <BidFiles bid={bid.id} setMessage={e=>setMessage(e)}/>
                        <Contact bid={bid} setMessage={e=>setMessage(e)}/>
                        <Comments bid={bid} setMessage={e=>setMessage(e)}/>
                    </Col>

                </Row>
            </MenuLayout>

        </>)
    } else {
        return (
            <MenuLayout>
                <Loader />
            </MenuLayout>)
    }
}

function Comments({ bid,setMessage }) {

    const [comments, setComments] = useState()

    function fetchComment(size) {
        GetHttp(`/bid/${bid.id}/comment`, { sort: 'date,DESC', size: size })
            .then(e => {
                if (e.ok) {
                    e.json().then(json => setComments(json));

                }
            })
    }

    const addComment = (text) => {
        if (text.length > 0) {
            PostHttp(`/bid/${bid.id}/comment`, { message: text })
                .then((res) => {
                    if(res.ok){
                        fetchComment();
                    }else {
                        res.json().then(json=>setMessage(json.message));
                    }
                });
        }
    }

    useEffect(() => fetchComment(5), [])
    if (comments) {
        return (<>
            <div className='bg-white p-2 mt-2'>
                <div className='text-center fw-bold'>Комментарий к заявке</div>
                <div className='p-1'>
                    {comments.content.sort((e,v)=>e.date-v.date).map((r,i) => {
                        return <Comment key={i} comment={r} onUpdate={() => fetchComment()} hasDelete={r.owner.id} />
                    })}
                    {!comments.last ? <div className='text-muted p-1 text-center' style={{ cursor: 'pointer' }} onClick={() => fetchComment(comments.size + 5)}>Показать еще комментарий </div> : ""}
                </div>
                <TextEditor height="200px" text="" onTextSave={addComment} />
            </div>
        </>)
    } else {
        return <Loader />
    }
}

function BidDetails({ bid, update,setMessage }) {
    const nav = useNavigate();
    const [user] = useContext(UserContex);
    const [image, setImage] = useState();

    function onOwnerClick(owner) {
        nav("/main/employee", { state: { employee: owner } })
    }

    function reducer(act, target) {
        act[target.name] = target.value;
        PostHttp(`/bid/${bid.id}/update`, act)
            .then((res) => {
                if(res.ok){
                    update();
                }else {
                    res.json().then(json=>setMessage(json.message));
                }
            });

        return act;
    }

    const [chengedBid, setChangedBid] = useReducer(reducer, { header: bid.header, text: bid.text, expiredDate: bid.expiredDate, priority: bid.priority, status: bid.status });

    function fetchAvatar(uuid) {
        if (uuid !== "/default-avatar.png" && uuid!=null) {
            GetHttp("/file/uuid/" + uuid.split(".")[0]).then(e => {
                if (e.ok) {
                    e.blob().then(b => setImage(URL.createObjectURL(b)));
                }
            })
        } else {
            setImage("/default-avatar.png")
        }
    }

    useEffect(() => {
        fetchAvatar(bid.owner.avatar);

    }, [])
    const dateExpired = new Date(bid.expiredDate * 1000);
    // const expTime  = `${dateExpired.getFullYear()}-${dateExpired.getMonth()}-${dateExpired.getDay()}T00:00`;
    const expTime = dateExpired.toISOString().split(".")[0]

    return (<>
        <div className='p-2 bg-white my-1' style={{ minHeight: "300px" }}>
            <Container fluid>
                <Row >
                    <Col className='d-flex align-items-center bg-light p-2'>
                        <div className='mx-2'><Image roundedCircle width="35" src={image}></Image></div>
                        <div className='text-primary w-100' onClick={() => onOwnerClick(bid.owner)} style={{ cursor: "pointer" }}>{bid.owner.firstName + " " + bid.owner.lastName}</div>
                        <div className='ms-auto text-nowrap'><b>{'Дата \u00a0'}</b>{new Date(bid.date * 1000).toLocaleString()}</div>
                    </Col>
                </Row>
                <Row className='lg-4 sm-1 text-start flex-wrap bg-light border-bottom  p-2'>
                    <Col className="text-start text-nowrap " >
                        <b>{'Приоритет \u00a0'}</b>
                        <Form.Select aria-label="select priority" size="sm" className='' onChange={(e) => setChangedBid({ value: e.target.value, name: "priority" })}>
                            <option value="1" selected={chengedBid.priority === 1}>Нормальный</option>
                            <option value="2" selected={chengedBid.priority === 2}>Срочный</option>
                            <option value="3" selected={chengedBid.priority === 3}>Низкий</option>
                        </Form.Select>
                    </Col>
                    <Col className="text-start text-nowrap "><b>{'Статус \u00a0'}</b>
                        <Form.Select aria-label="select priority" size="sm" className='' onChange={(e) => setChangedBid({ value: e.target.value, name: "status" })} defaultValue={chengedBid.status}>
                            <option value="newbid" >{bidStausResolver("newbid")}</option>
                            <option value="workprogress" >{bidStausResolver("workprogress")}</option>
                            <option value="expired" >{bidStausResolver("expired")}</option>
                            <option value="complited">{bidStausResolver("complited")}</option>
                        </Form.Select>
                    </Col>
                    <Col>
                        <div className='fw-bold'>Срок</div>
                        <Form.Control size="sm" type="datetime-local" onChange={(e) => setChangedBid({ value: e.target.value + "+0000", name: "expiredDate" })} defaultValue={expTime} />
                    </Col>
                </Row>
                <Row className='lg-4 sm-1 text-start flex-wrap bg-light border-bottom  p-2'>
                    <Col>
                        <Executors bid={bid}/>
                    </Col>
                </Row>
                <Row className='lg-2 sm-1 p-1 mt-2'>
                    <Col sm={2} className='me-1 fw-bold '>Тема</Col>
                    <Col>
                        <div className=''>{bid.header}</div>
                    </Col>
                </Row>
                <Row className='lg-2 sm-1 p-1 mt-2 '>
                    <TextBlock editable={bid.owner.id == user.id} bid={bid} onSave={(e) => setChangedBid(e)} />
                </Row>

            </Container>

        </div>
    </>)
}

function Executors({bid,setMessage}) {

    const [executors,setExecutors] = useState();
    const [showDialod,setShowDialog] =useState(false);

    function fetch(size){
        GetHttp(`/bid/${bid.id}/executors?size=${size}`)
        .then(res=>{
            if(res.ok){
                res.json().then(json=>setExecutors(json));
            }
        })

    }

    useEffect(()=>fetch(10),[bid.id])

    function handleDeleteExecutor(employee){
        DeleteHttp(`/bid/${bid.id}/executors/del/${employee}`)
        .then((res)=>{
            if(res.ok){
                fetch();
            }else {
                res.json().then(json=>setMessage(json.message));
            }
        })
    }

    if(executors){
    return (<>
        <ExecutorDialog show={showDialod} bid={bid} onHide={()=>{setShowDialog(false);fetch(10);}} ignore={executors.content.map(e=>e.id)} setMessage={setMessage}/>
        <div style={{ cursor: 'pointer' }} className='text-center fw-bold' data-bs-toggle="collapse" data-bs-target="#collapseExecutors">Исполнители ({executors.totalElements})</div>
        <div className="collapse mt-2" id="collapseExecutors">
            <div className='d-flex flex-wrap'>
                {executors.content.map((m,i)=>{
                    if(bid.owner.id!=m.id){
                        return (
                        <div key={i} className='p-1 m-1 text-primary text-decoration-underline' size="sm">{`${m.firstName} ${m.lastName}`}
                            <i className="bi bi-x-circle text-danger ms-1" onClick={()=>handleDeleteExecutor(m.id)}></i>
                        </div>)
                    }else {
                        return (<div key={i} className='p-1 m-1 text-primary text-decoration-underline' size="sm">{`${m.firstName} ${m.lastName}`}</div>)
                    }
                })} 
            </div>
            <GetMore pageable={executors} onClick={(e)=>fetch(e+10)}/>
            <div className='text-center'>
            <Button variant="outline-success" className='p-1 m-1' size="sm" onClick={()=>setShowDialog(true)}>Добавить</Button>
            </div>
        </div>
    </>
    )}else {
        return <Loader />
    }
}

function ExecutorDialog({show,bid,onHide,ignore,setMessage}){

    const [employees,setEmployees] = useState({content:[],last:true});
   

    function fetchEmployees(size){
        GetHttp(`/employee/active?size=${size}`)
        .then(e=>{
            if(e.ok){
                e.json().then(json=>setEmployees(json));
            }
        });
        
    }

    const [listEmployees ,setListEmployees] = useState([]);

    useEffect(()=>fetchEmployees(10),[show])

    function addEmployee(id){
        
        if(listEmployees.some(e=>e.id==id)){
            const ar = listEmployees.filter(f=>f.id!==id);
            setListEmployees(ar)
        }else {
            listEmployees.push({id:id});
            setListEmployees([...listEmployees])
        }
        
    }

    const onAdd=()=>{
        if(listEmployees.length>0){
            PostHttp(`/bid/${bid.id}/executors/add`,listEmployees)
            .then((res)=>{
                if(res.ok){
                    onHide();
                }else {
                    res.json().then(json=>setMessage(json.message));
                }
            });
        }
    }

    return(<Modal show={show} onHide={()=>onHide()}>
        <Modal.Header closeButton>
            <Modal.Title>Добавить исполнителей</Modal.Title>
        </Modal.Header>
        <ModalBody>
            <Table>
                <tbody>
                    {employees.content.map((e,i)=>{
                        if(ignore.some(f=>f== e.id)){
                            return(
                                <tr key={i}>
                                    <td>{e.firstName}</td>
                                    <td>{e.lastName}</td>
                                    <td><FormCheck checked={true} disabled/></td>
                                </tr>
                        )}else {
                            return(
                                <tr key={i}>
                                    <td>{e.firstName}</td>
                                    <td>{e.lastName}</td>
                                    <td><FormCheck checked={listEmployees.some(e2=>e2.id==e.id)} onChange={()=>addEmployee(e.id)}/></td>
                            </tr>
                                )
                        }
                    })}
                </tbody>
            </Table>
            <GetMore pageable={employees} onClick={()=>fetchEmployees(employees.totalElements+10)} />
            
        </ModalBody>
        <ModalFooter>
            <div>
                <Button className="me-2" variant="success" onClick={()=>onHide()}>Закрыть</Button>
                <Button onClick={onAdd}>Добавить и закрыть</Button>
            </div>
        </ModalFooter>
    </Modal>)
}

function TextBlock({ editable, bid, onSave }) {

    const [enableEdit, setEnableEdit] = useState(false);

    if (bid.enableShadowRoot) {
        if (editable) {
            if (enableEdit) {
                return (<>
                    <Col sm={2} className='me-1'>Текст заявки</Col>
                    <TextEditor height="250px" text={bid.text} onTextSave={(s) => { onSave({ name: "text", value: s }); setEnableEdit(false) }} />
                </>)
            } else {
                return (<>
                    <Col sm={2} className='me-1 text-primary text-decoration-underline' style={{ cursor: 'pointer' }} onClick={() => setEnableEdit(true)}>Текст заявки</Col>
                    <Col sm={9}>
                        { }
                        <root.div >
                            <div className='' dangerouslySetInnerHTML={{ __html: bid.text }} ></div>
                        </root.div>
                    </Col></>)
            }
        } else {
            return (<>
                <Col sm={2} className='me-1 fw-bold'>Текст заявки</Col>
                <Col sm={9}>
                    <root.div>
                        <div className='' dangerouslySetInnerHTML={{ __html: bid.text }} ></div>
                    </root.div>
                </Col></>)
        }
    } else {

        if (editable) {
            if (enableEdit) {
                return (<>
                    <Col sm={2} className='me-1'>Текст заявки</Col>
                    <TextEditor height="250px" text={bid.text} onTextSave={(s) => { onSave({ name: "text", value: s }); setEnableEdit(false) }} />
                </>)
            } else {
                return (<>
                    <Col sm={2} className='me-1 text-primary text-decoration-underline' style={{ cursor: 'pointer' }} onClick={() => setEnableEdit(true)}>Текст заявки</Col>
                    <Col sm={9}>
                        { }
                        <div >
                            <div className='' dangerouslySetInnerHTML={{ __html: bid.text }} ></div>
                        </div>
                    </Col></>)
            }
        } else {
            return (<>
                <Col sm={2} className='me-1 fw-bold'>Текст заявки</Col>
                <Col sm={9}>
                    <div>
                        <div className='' dangerouslySetInnerHTML={{ __html: bid.text }} ></div>
                    </div>
                </Col></>)
        }

    }

}

function CheckList({ bid,setMessage }) {

    const [checklist, setCheckList] = useState([]);

    function fetchCheckList() {
        GetHttp(`/bid/${bid.id}/checklist`)
            .then((res) => {
                if (res.ok) {
                    res.json().then(json => setCheckList(json));
                }
            })

    }
    const [newChecklist, setNewCheckList] = useState("");

    function onAddCheckList() {
        if (newChecklist.length > 0) {
            PostHttp(`/bid/${bid.id}/checklist`, { label: newChecklist })
                .then((res) => {
                    if(res.ok){
                    fetchCheckList();
                    setNewCheckList("")
                    }else {
                        setNewCheckList("")
                        res.json().then(json=>setMessage(json.message));
                    }
                });
        }
    }

    function onDelete(id) {
        DeleteHttp(`/bid/${bid.id}/checklist/${id}`)
            .then((res) =>{
                if(res.ok){   
                    fetchCheckList();
                }else {
                    res.json().then(json=>setMessage(json.message));
                };
            })
    }

    useEffect(() => fetchCheckList(), []);

    function onChecked(id) {
        PutHttp(`/bid/${bid.id}/checklist/${id}`)
            .then((res) => {
                if(res.ok){   
                    fetchCheckList();
                }else {
                    res.json().then(json=>setMessage(json.message));
                };
            })
    }

    return (<>
        <div className='bg-white p-2 mt-2'>
            <div className='text-center fw-bold'>Чек-лист</div>

            {checklist.map((ck, i) => {
                return <div key={i} className='d-flex p-2 mx-2'>
                    <Form.Check type="checkbox" label={ck.checked ? <s> {ck.label}</s> : ck.label} checked={ck.checked} onChange={() => onChecked(ck.id)} />
                    <div className='ms-2' onClick={() => onDelete(ck.id)}>
                        <i className="text-danger bi bi-x fs-5" style={{ cursor: 'pointer' }}></i>
                    </div>
                </div>
            })
            }
            <div className='d-flex mt-2 justify-content-center'>
                <div><FormControl size="sm" value={newChecklist} onChange={(e) => setNewCheckList(e.target.value)} /></div>
                <div><Button size="sm" onClick={() => onAddCheckList()}>Добавить</Button>
                </div>
            </div>
        </div>
    </>)
}

function Contact({ bid,setMessage }) {

    const [contactsCfg, setContactsCfg] = useState([])

    function fetchContacts() {
        GetHttp(`/bid/${bid.id}/contacts`)
            .then(res => {
                if (res.ok) {
                    res.json().then(json => setContactsCfg(json));
                }
            })
    }

    function deleteContact(contactid) {
        DeleteHttp(`/bid/${bid.id}/contacts/${contactid}`)
            .then((res) => {
                if(res.ok){   
                    fetchContacts();
                }else {
                    res.json().then(json=>setMessage(json.message));
                };
            })
    }

    function onSetUseEmail(cfg) {
        const isUseEmail = cfg.useEmail === "off" ? "on" : "off";
        PutHttp(`/bid/${bid.id}/contacts`, { id: cfg.id, useEmail: isUseEmail, useTelegram: cfg.useTelegram })
            .then(() => { fetchContacts() });
    }
    function onSetUseTelegram(cfg) {
        const isUseTelegram = cfg.useTelegram === "off" ? "on" : "off";
        PutHttp(`/bid/${bid.id}/contacts`, { id: cfg.id, useEmail: cfg.useEmail, useTelegram: isUseTelegram })
            .then(() => { fetchContacts() });
    }

    useEffect(() => fetchContacts(), []);

    const [selectContact, setSelectContact] = useState(false);

    return (<>
        <ViewContacts show={selectContact} onHide={() => { fetchContacts(); setSelectContact(false) }} bid={bid} exists={contactsCfg} />
        <div className='bg-white p-2 my-2'>
            <div style={{ cursor: 'pointer' }} className='text-center fw-bold' data-bs-toggle="collapse" data-bs-target="#collapseContacts">{`Контакты (${contactsCfg.length})`}</div>
            <div id="collapseContacts" className='collapse'>
                <div className='row mx-2'>
                    {contactsCfg.map((con, i) => {
                        let emailState = "text-secondary";
                        if (con.useEmail === 'on') { emailState = "text-success" }
                        else if (con.useEmail === "disabled") { emailState = "text-danger" }

                        return (
                            <Col key={i} sm="4" className='p-0 my-1'>
                                <div className='d-flex border rounded p-2 align-items-center'>
                                    <div>{con.contact.firstName + " " + con.contact.orgName}</div>
                                    <div className="mx-2" onClick={() => onSetUseTelegram(con)} style={{ cursor: 'pointer' }}>
                                        <i className={`bi bi-telegram ${con.useTelegram === "on" ? "text-primary" : ""}`}></i>
                                    </div>
                                    <div className="mx-2" onClick={() => onSetUseEmail(con)} style={{ cursor: 'pointer' }}>
                                        <i className={`bi bi-mailbox ${emailState}`}></i>
                                    </div>
                                    <div className='ms-auto' onClick={() => deleteContact(con.id)}><i className="text-danger bi bi-x fs-5" style={{ cursor: 'pointer' }}></i></div>
                                </div>
                            </Col>)
                    })}
                </div>
                <Row>
                    <Col>
                        <div className='d-flex my-2' >
                            <Button className='mx-auto' variant="link" size="sm" onClick={() => setSelectContact(true)}>Добавить</Button>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>

    </>)
}

function Comment({ comment, onUpdate,setMessage }) {

    const nav = useNavigate();
    const [user] = useContext(UserContex);

    const onDelete = () => {
        DeleteHttp(`/bid/${comment.id}/comment`, { commentid: comment.id })
            .then((res) => {
                if(res.ok){   
                    onUpdate();
                }else {
                    res.json().then(json=>setMessage(json.message));
                };
            });
    }
    const onOwnerClick = () => {
        nav("/main/employee", { state: { employee: comment.owner } })
    }
    if (comment.owner.id == user.id) {
        return (<>
            <div className='p-3 my-1 shadow-sm  mt-4 border rounded' >
                <div className='d-flex  text-secondary'>
                    <div className='me-auto text-primary text-decoration-underline' onClick={onOwnerClick}>{comment.owner.firstName + " " + comment.owner.lastName}</div>
                    <div>{new Date(comment.date * 1000).toLocaleString()} </div>
                </div>
                <div dangerouslySetInnerHTML={{ __html: comment.message }} className='my-2'></div>
                <div className=''>
                    <div className='text-muted text-decoration-underline' variant='link' style={{ fontSize: '12px', cursor: "pointer " }} onClick={onDelete}>удалить</div>
                </div>
            </div>
        </>)
    } else {
        return (<>
            <div className='p-3 my-1 shadow-sm  mt-4 border rounded' >
                <div className='d-flex  text-secondary'>
                    <div className='me-auto text-primary text-decoration-underline' onClick={onOwnerClick}>{comment.owner.firstName + " " + comment.owner.lastName}</div>
                    <div>{new Date(comment.date).toLocaleString()} </div>
                </div>
                <div dangerouslySetInnerHTML={{ __html: comment.message }} className='my-2'></div>
            </div>
        </>)
    }
}

function ViewContacts({ show, onHide, bid, exists }) {

    const [contacts, setContacts] = useState({ content: [] });

    function fetchContact() {
        GetHttp("/contact").then(res => {
            if (res.ok) {
                res.json().then(json => setContacts(json));
            }
        })
    }

    const [newContacts, setNewContacts] = useState([]);

    function sendNewContact() {
        PostHttp(`/bid/${bid.id}/contacts`, newContacts).then(() => {
            setNewContacts([])
            onHide();
        }
        );
    }

    function handleCheck(id) {
        if (newContacts.some(e => e.id === id)) {
            setNewContacts(newContacts.filter(i => i.id !== id));
        } else {
            newContacts.push({ id: id });
            setNewContacts([...newContacts]);
        }
    }



    useEffect(() => {
        fetchContact();
    }, [exists])

    return (<>
        <Modal show={show} onHide={onHide}>
            <Modal.Header>Добавить контакт</Modal.Header>
            <Modal.Body>
                <Table>
                    <tbody>
                        {contacts.content.map((c, i) => {
                            if (exists.length > 0) {
                                if (!exists.some(e => e.contact.id == c.id)) {
                                    return (
                                        <tr key={i}>
                                            <td>{c.firstName + " " + c.surName}</td>
                                            <td>{c.orgName}</td>
                                            <td>{c.email}</td>
                                            <td><Form.Check onChange={() => handleCheck(c.id)} /></td>
                                        </tr>)
                                }
                            } else {
                                return (
                                    <tr key={i}>
                                        <td>{c.firstName + " " + c.surName}</td>
                                        <td>{c.orgName}</td>
                                        <td>{c.email}</td>
                                        <td><Form.Check onChange={() => handleCheck(c.id)} /></td>
                                    </tr>)
                            }
                        })}
                    </tbody>

                </Table>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onHide}>Закрыть</Button>
                <Button onClick={() => sendNewContact()} variant='success' disabled={newContacts.length == 0}>Добавить</Button>
            </Modal.Footer>

        </Modal>
    </>)
}

export function getStatus(status) {
    switch (status) {
        case 'newbid': { return 'Новая' }
        case 'workprogress': { return 'В работе' }
        case 'expired': { return 'Просрочена' }
        case 'complited': { return 'Выполнено' }
    }
}
export function getPriority(status) {
    switch (status) {
        case 1: { return <div>Обычный<i className="bi bi-flag text-primary ms-2"></i></div> }
        case 2: { return <div>Срочный<i className="bi bi-fire ms-2 text-danger"></i></div> }
        case 3: { return <div>Низкий<i className="bi bi-clock ms-2 text-success"></i></div> }
    }
}

export function NewBid({ menu }) {

    const [newbid, setNewbid] = useState({ header: '', text: '', priority: 1, status: 'newbid', expiredDate: '' });
    const nav = useNavigate();
    const [message,setMessage] = useState();

    const onChangeHeader = (event) => {
        setNewbid({ text: newbid.text, header: event.target.value, priority: newbid.priority, expiredDate: newbid.expiredDate, status: newbid.status })
    }
    const onChangeText = (text) => {
        setNewbid({ text: text, header: newbid.header, priority: newbid.priority, expiredDate: newbid.expiredDate, status: newbid.status })
    }

    const onSend = () => {
        PostHttp("/bid", newbid).then((res) => {
            if(res.ok){
                nav("/main/bids")
            }else {
                res.json().then(json=>setMessage(json.message));
            }
        });
    }

    const onChangePriority = (e) => {
        setNewbid({ header: newbid.header, text: newbid.text, priority: e.target.value, status: 'newbid', expiredDate: '' })
    }

    return (<>
        <MenuLayout message={message}>
            <Row>
                <Col className='pe-1' lg={10}>
                    <div className='p-2 bg-white my-1 fw-bold'>
                        <Form.Label >Тема</Form.Label>
                        <Form.Control defaultValue={newbid.header} onChange={onChangeHeader} />
                    </div>
                    <TextEditorSimple height="200px" text="" onChange={onChangeText} />
                </Col>
            </Row>
            <Row>
                <Col lg={10} className='pe-1 mt-1'>
                    <div className=' p-2 bg-white'>
                        <div className='fw-bold text-start ms-1 mb-2'>Приоритет</div>
                        <Form.Select aria-label="select priority" onChange={onChangePriority} style={{ maxWidth: "14em" }}>
                            <option value="1" selected>Обычный приоритет</option>
                            <option value="2">Срочный приоритет</option>
                            <option value="3">Низкий приоритет</option>
                        </Form.Select>
                    </div>
                    <div className='p-2 bg-white'>
                        <Button variant='outline-success' onClick={onSend} disabled={newbid.text.length === 0 || newbid.header.length === 0}><i className="bi bi-send me-2"></i>Отправить</Button>
                    </div>
                </Col>
            </Row>
        </MenuLayout>

    </>)
}


function BidFiles({ bid }) {


    const [message, setMessage] = useState();
    const [metaFiles, setMetaFiles] = useState([])

    function fetchImages() {
        GetHttp(`/bid/${bid}/files`)
            .then(res => {
                if (res.ok) {
                    res.json().then(json => setMetaFiles(json));
                }
            })
    }
    useEffect(() => { fetchImages() }, []);




    const uploadImage = (file) => {
        const fd = new FormData();
        fd.append("file", file.target.files[0]);
        PostHttpMultipart(`/bid/${bid}/files`, fd)
            .then((res) => {
                if (res.ok) {
                    fetchImages();
                } else {
                    if (res.status == 413) {
                        GetHttp("/file/limits").then(lim => {
                            if (lim.ok) {
                                lim.json().then(json1 => setMessage("Максимальный размер файла " + json1.maxFileSize))

                            }
                        })
                    }
                }
            })
    }

    const fileSelect = useRef(null);

    return (<>
        <div className='bg-white p-2 mt-2'>
            <div style={{ cursor: 'pointer' }} className='text-center fw-bold' data-bs-toggle="collapse" data-bs-target="#collapsePhfoto">Фотографии документы</div>
            {message ? <Alert variant="danger" dismissible className='mt-1'>{message}</Alert> : ''}
            <div className="collapse mt-2" id="collapsePhfoto">
                <div className='d-flex flex-wrap'>
                    {metaFiles.map(e => {
                        return (<BidImage metaFile={e} update={() => fetchImages()} />)
                    })}
                    <div className='position-relative image-button'>
                        <Form.Control ref={fileSelect} name="file" type="file" accept='image/*, videi/*,.pdf,doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document' hidden onChange={uploadImage} />
                        <div onClick={() => fileSelect.current.click()} className={`border rounded d-flex align-items-center justify-content-center  h-100 p-1`} style={{ width: "200px", minHeight: "200px", cursor: "pointer" }}>
                            <i className="bi bi-plus-circle my-auto"></i>
                        </div>
                    </div>
                </div>

            </div>

        </div>

    </>)
}
function BidImage({ metaFile, update }) {
    const [img, setImg] = useState();

    function fetchImage() {
        if (metaFile.extention === "jpg" || metaFile.extention === "png" || metaFile.extention === "jpeg") {
            GetHttp("/file/uuid/" + metaFile.uuid).then(e => {
                if (e.ok) {
                    e.blob().then(b => setImg(b));
                }
            })
        }
    }

    useEffect(() => { fetchImage(); }, [])

    const onDeleteFile = () => {
        DeleteHttp(`/bid/${metaFile.targetId}/files/${metaFile.id}`)
            .then(() => update());
    }

    const dw = useRef();

    const download = () => {
        GetHttp(`/file/uuid/${metaFile.uuid}`)
            .then(res => {
                if (res.ok) {
                    res.blob().then(b => {

                        const filename = res.headers.get('content-disposition').split(";")[1].split("?")[3];

                        dw.current.href = window.URL.createObjectURL(b);
                        dw.current.download = filename;
                        dw.current.click();

                    })

                }
            })
    }

    if (metaFile.extention === "jpg" || metaFile.extention === "png" || metaFile.extention === "jpeg") {
        return (
            <div className='position-relative image-button border me-2'>
                <div className='position-absolute end-0 d-none'>
                    <i className="bi bi-trash text-danger rounded-circle p-2 bg-secondary-subtle" onClick={onDeleteFile}></i>
                </div>
                {img ? <Image className="p-1 m-1" src={URL.createObjectURL(img)} width={200} style={{ maxHeight: "200px" }} /> : "/default-avatar.png"}
            </div>
        )
    } else {
        return (<div className='position-relative image-button border me-2' style={{ width: "200px", height: "200px" }}>
            <div className='position-absolute end-0 d-none'>
                <i className="bi bi-trash text-danger rounded-circle p-2 bg-secondary-subtle" onClick={onDeleteFile}></i>
            </div>
            <div className="position-absolute top-50 start-50 translate-middle"><i class="bi bi-file-earmark-text fs-1"></i></div>
            <div className='position-absolute bottom-0 start-50 translate-middle text-primary' onClick={download} style={{ cursor: 'pointer' }}>Скачать</div>
            <a href="" hidden ref={dw} />
        </div>)
    }
}

function BidHistory({ bid }) {

    const [history, setHistory] = useState()

    function fetch(size) {
        GetHttp(`/bid/${bid.id}/history?sort=created,DESC&size=${size}`)
            .then(res => {
                if (res.ok) {
                    res.json().then(json => setHistory(json));
                }
            })
    }

    useEffect(() => fetch(5), [bid.id])

    if (history) {
        return (<>
            <div className='bg-white p-2 mt-2'>
                <div style={{ cursor: 'pointer' }} className='text-center fw-bold' data-bs-toggle="collapse" data-bs-target="#collapseBidhistory">История заявки</div>
                <div className="collapse mt-2" id="collapseBidhistory">
                    <div>
                        {history.content.sort((e,e1)=>e.id-e1.id).map((h, i) => {
                            return (
                                <div className='m-2 p-1 ' key={i}>
                                    <div className='text-muted m-2'>
                                        <span>{h.employee.firstName + " " + h.employee.lastName}</span>
                                        <span className='text-muted ms-3 mb-1' style={{ fontSize: "10px" }}>{new Date(h.created * 1000).toLocaleString()}</span>
                                    </div>
                                    <div className='m-2'>{`${h.text}`}</div>
                                </div>)
                        })}
                        <GetMore pageable={history} onClick={()=>fetch(history.size+5)} />
                    </div>
                </div>
            </div>
        </>)
    } else {
        return (<Loader />)
    }

}