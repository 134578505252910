import React, { useContext, useEffect, useRef, useState } from 'react';
import { MenuLayout } from '../common/layout';
import { Col, Image, Row, Form, Button, Spinner } from 'react-bootstrap';
import { PostHttp, GetHttp, DeleteHttp } from '../assets/connector';
import Loader from '../common/spinner';
import { GetMore } from '../common/tables';
import { UserContex } from '../App';

export function Chats(props) {
    const searcInput = useRef(null);
    const [employee, setEmployee] = useState();
    const [showSpinner, setShowSpinner] = useState(false);

    let [timeoutid, setTimeoutid] = useState();
    const [userContext] = useContext(UserContex);
    const [chats, setChats] = useState();

    function fetchChats() {
        GetHttp("/chat").then(res => {
            if (res.ok) {
                res.json().then(json => setChats(json));
            }
        })
    }
    useEffect(() => { fetchChats() }, []);

    const onSearchInput = (event) => {
        setShowSpinner(true);
        if (timeoutid !== undefined) {
            clearTimeout(timeoutid);
        }
        setTimeoutid(setTimeout(() => {
            setShowSpinner(false);
            fetchEmployee(5, event.target.value);
        }, 2000));
    }

    function fetchEmployee(size, search) {
        PostHttp(`/employee/search?size=${size}`, { searchString: search }).then(res => {
            if (res.ok) {
                res.json().then(json => setEmployee(json));
            } else {
                setShowSpinner(false);
            }
        })
    }

    const onClearSearchEmployee = () => {
        searcInput.current.value = ''
        setEmployee();
    }

    const [currentChat, setCurrentChat] = useState()

    function handleAddChat(memberid) {
        PostHttp("/chat", { members: [{ id: memberid }] })
            .then(() => fetchChats())
    }

    function onDeleteChat(id) {
        DeleteHttp(`/chat/${id}`).then(() => {
            fetchChats();
            setCurrentChat()

        })
    }

    return (<>
        <MenuLayout items={props.menu}>
            <Row onClick={(e) => setEmployee()}>
                <Col sm={3} className='p-1 mb-1'>
                    <div className='p-1 d-flex'>
                        <Form.Control onChange={onSearchInput} ref={searcInput} />
                        {showSpinner ? <div className=''><Spinner /></div> : <Button className='mx-1 rounded m-1' variant="outline-primary" size="sm" onClick={onClearSearchEmployee}>Х</Button>}
                        {employee !== undefined && employee.content.length > 0 ?
                            <div className={`position-absolute bg-white z-3 p-3 m-5 start-0 ${employee === undefined ? 'd-none' : ""}`} style={{ width: "auto", top: "10px" }}>
                                <div className='p-2 m-2'>
                                    {employee.content.map((e, i) => {
                                        if (e.id !== userContext.id) {
                                            return (<li key={i} onClick={() => { handleAddChat(e.id) }}>{e.firstName + " " + e.lastName}</li>)
                                        }else {return""}
                                    })}
                                    <div className='p-2 m-1'>
                                        <GetMore pageable={employee} onClick={() => fetchEmployee(employee.size + 5, searcInput.current.value)} />
                                    </div>
                                </div>
                                <div className="text-center text-primary" onClick={onClearSearchEmployee} style={{ cursor: "pointer" }}>Очистить</div>
                            </div> : ""}
                    </div>
                    {/* Chats list */}
                    <ChatList onClickChat={(chat) => setCurrentChat(chat)} chats={chats} onDeleteChat={(id) => { onDeleteChat(id) }} />
                </Col>

                <Col sm={8} className='p-1 mb-1' >
                    {currentChat !== undefined ?
                        <ChatPane currentChat={currentChat} />
                        : <div className='text-center bg-white h5 p-4 my-2'>Выбирите чат</div>}
                </Col>
            </Row>
        </MenuLayout></>)

}



function ChatPane({ currentChat }) {

    const [userContext] = useContext(UserContex);

    function onSend() {
        const textArea = document.querySelector('textarea');
        if (text.length > 0) {
            textArea.classList.remove('is-invalid')
            PostHttp(`/chat/${currentChat.id}/message`, { message: text, from: { id: userContext.id } })
                .then(() => fetchChatMessage(messages.size + 1))
        } else {
            textArea.classList.add('is-invalid')
        }
        textArea.value = '';


    }

    const [text, setText] = useState();

    const [messages, setMessages] = useState();

    function fetchChatMessage(size) {
        GetHttp(`/chat/${currentChat.id}/message?sort=created,DESC&size=${size}`)
            .then(res => {
                if (res.ok) {
                    res.json().then(json => setMessages(json));
                }
            })
    }

    useEffect(() => { fetchChatMessage(5) }, [currentChat])

    if (messages && messages.content.length > 0) {
        return (<>
            {/* //chat members */}
            <div className=" me-auto bg-white p-2 my-1">
                <div className="fw-bold p-2 d-flex">
                    {currentChat.members.map(m => {
                        if (m.id !== userContext.id) {
                            return (<div className='p-1 mx-1'>Чат с {m.firstName + " " + m.lastName}</div>)
                        } else {
                            return ""
                        }
                    })}
                </div>
            </div>
            {/* //chat history */}
            <div className='my-3 bg-white ' >
                <div >
                    {!messages.last ?
                        <div className="text-center fw-bold text-decoration-underline p-2" style={{ cursor: "pointer" }} onClick={() => fetchChatMessage(messages.size + 10)}>Показать страрые сообщения</div> : ""}
                    <div className=' '>
                        {messages.content.sort((e, e1) => e.id - e1.id).map((m, i) => {
                            if (userContext.id === m.from.id) {
                                return (
                                    <div className=' p-3 my-2 me-2' key={i}>
                                        <div className='fw-bold border-bottom text-primary'>{m.from.firstName + " " + m.from.lastName + " " + new Date(m.created * 1000).toLocaleString()}</div>
                                        <div className='p-2 my-1'>{m.message}</div>
                                    </div>
                                )
                            } else {
                                return (
                                    <div className=' p-3 my-2 ms-2 '  key={i}>
                                        <div className='fw-bold border-bottom text-success'>{m.from.firstName + " " + m.from.lastName + " " + new Date(m.created * 1000).toLocaleString()}</div>
                                        <div className='p-2 my-1'>{m.message}</div>
                                    </div>)
                            }
                        })}
                    </div>
                </div>
            </div>
            <div className='d-flex'>
                <Form.Control as="textarea" className='' rows="1" onChange={(e) => setText(e.target.value)} />
                <div className='ms-2 mt-1 mt-auto'>
                    <Button size="" className="rounded" onClick={() => onSend()}>send</Button>
                </div>
            </div>
        </>
        )
    } else {
        return (
            <>
                <div className=" me-auto bg-white p-2 my-1">
                    <div className="fw-bold p-2 d-flex">{currentChat.members.map(m => {
                        if (m.id !== userContext.id) {
                            return (<div className='p-1 mx-1'>Чат с {m.firstName + " " + m.lastName}</div>)
                        } else {
                            return ""
                        }
                    })}</div>
                </div>
                <div className='p-2 my-2 text-center bg-white'>Нет Сообщений</div>
                <div className='d-flex '>
                    <Form.Control as="textarea" className='' rows="1" />
                    <div className=''>
                        <Button size="" className="rounded" onClick={() => onSend()}>send</Button>
                    </div>
                </div>
            </>
        )

    }
}

function ChatList({ onClickChat, chats, onDeleteChat }) {

    const [userContext] = useContext(UserContex);



    if (chats) {
        return (<div >
            {chats.map((c, i) => {
                return (
                    <div key={i} className="d-flex me-auto bg-white p-2 mx-2 " onClick={() => onClickChat(c)} style={{ cursor: "pointer" }}>
                        <div className='my-auto'><Image src="/default-avatar.png" width={34} className='rounded-circle' /></div>
                        <div className='ms-3'>
                            <div className='fw-bold'>{c.members.map(e => {
                                if (e.id !== userContext.id) {
                                    return (e.firstName + " " + e.lastName)
                                }
                            })}</div>
                            <div>{new Date(c.created * 1000).toLocaleString()}</div>
                        </div>
                        <div className='ms-auto my-auto '><Button size="sm" variant='link' onClick={() => onDeleteChat(c.id)}>удалить</Button></div>

                    </div>)

            })}
        </div>
        )
    } else {
        return (<Loader />)
    }
}