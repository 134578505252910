import React, { useContext, useEffect, useState } from 'react';
import { MenuLayout } from '../common/layout';
import { Form, Row,Col ,Image, Container} from 'react-bootstrap';
import Loader from '../common/spinner';
import { GetHttp,PostHttp,PostHttpMultipart, PutHttp} from '../assets/connector';
import {useResponseStatusCheck } from '../common/common';
import { UserContex } from '../App';
import { FileuploadPopup } from '../common/files';

export function UserInfo(){
    
   
    const [userInfo,fetch] = useContext(UserContex);
    const [selected,setSelected] = useState(0);
    const checkStatus = useResponseStatusCheck();
    const [departments,setDepartments] = useState([]);
    const [avatar,setAvatar] = useState();
    const [message,setMessage] = useState();

    const [profile,setProfile] =useState({firstName:userInfo.firstName,lastName:userInfo.lastName});

    function onBlur(){
        if(profile.firstName.length>0  ){
        PostHttp("/profile",profile)
        .then((res)=>{
            if(res.ok){
            fetch();
            setSelected(0);
            }else{
                res.json().then(json=>setMessage(json.message));
            }
        })        
    }
    }

    const onChaneDepartment = (event) => {
        PutHttp(`/employee/${userInfo.id}`,{department:{id:event.target.value}})
        .then((res)=>{
            if(res.ok){
                fetch();
            }else{
                res.json().then(json=>setMessage(json.message));
            }
            
        });
        setSelected(0);
    }

    function fetchUserInfo(){
        if(userInfo.avatar !=="/default-avatar.png" && userInfo.avatar !=null){
        GetHttp("/file/uuid/" + userInfo.avatar.split(".")[0]).then(e => {
            if (e.ok) {
                e.blob().then(b => setAvatar(URL.createObjectURL(b)));
            }
        })
        }else {
            setAvatar("/default-avatar.png")
        }
    }

    function fetchDepartmens() {
        GetHttp("/department").then((d) => {
            if (d.ok) {
                checkStatus(d.status);
                d.json().then(j => setDepartments(j))

            }
        });
    }
    useEffect(() => {
        fetchDepartmens();
        fetchUserInfo();
    }, [userInfo]);

    const [showAvatarUpload,setShowAvatarUpload] = useState(false);

    function uploadAvatar(file, id) {
        const fd = new FormData();
        fd.append("file", file.target.files[0]);
        PostHttpMultipart(`/employee/${id}/avatar`, fd)
            .then(() => { 
                setShowAvatarUpload(false); 
                fetchUserInfo();
            })
    }

    if(userInfo){
        return(<>
            <MenuLayout message={message}>
            <FileuploadPopup show={showAvatarUpload} onChange={(e)=>{uploadAvatar(e,userInfo.id)}} onHide={()=>setShowAvatarUpload(false)} />
            <Container fluid className='bg-white p-2' >
                <Row>
                    <Col style={{maxWidth:"180px"}}>
                        <div className='my-auto' onClick={()=>setShowAvatarUpload(true)} style={{cursor:'pointer'}}>
                            <Image roundedCircle width="160"  src={avatar} ></Image>
                        </div>
                        
                    </Col>
                    <Col style={{maxWidth:"480px"}}>
                        <Form.Group className="mb-3" >
                            <Form.Label >Email address</Form.Label>
                            {selected === 1 ?
                            <Form.Control  type="email" defaultValue={userInfo.email} onBlur={()=>setSelected(0)}/>
                            : <div className='fs-5' onClick={()=>setSelected(1)}>{userInfo.email}</div>}
                        </Form.Group>                            
                        <div className='d-flex'>
                            <Form.Group className="mb-3 me-5" >
                                <Form.Label >Имя</Form.Label>
                                {selected === 2 ?
                                <Form.Control 
                                type="text" 
                                defaultValue={userInfo.firstName} 
                                onBlur={onBlur} 
                                onChange={(e)=>setProfile({firstName:e.target.value,lastName:profile.lastName})}/>
                                : <div className='fs-5' onClick={()=>setSelected(2)}>{userInfo.firstName?userInfo.firstName:"Укажите имя"}</div>}
                            </Form.Group>
                            <Form.Group className="mb-3 ms-5" >
                                <Form.Label>Фамилия</Form.Label>
                                {selected === 3?
                                <Form.Control 
                                type="text"
                                defaultValue={userInfo.lastName} 
                                onBlur={onBlur}
                                onChange={(e)=>setProfile({firstName:profile.firstName,lastName:e.target.value})}
                                />
                                : <div className='fs-5' onClick={()=>setSelected(3)}>{userInfo.lastName?userInfo.lastName:"Не указанно"}</div>}
                            </Form.Group>
                        </div>
                        <Form.Group className="mb-3" >
                            <Form.Label >Группа</Form.Label>
                            {selected === 4 ?
                                <Form.Select aria-label="select priority" className='' onChange={()=>setSelected(0)} defaultValue={userInfo.role}>
                                    <option value="admin">admin</option>
                                    <option value="operator">operator</option>
                                    <option value="other">other</option>       
                                </Form.Select>
                            : <div className='fs-5' onClick={()=>setSelected(4)}>{userInfo.role}</div>}
                        </Form.Group>
                        <Form.Group className="mb-3" >
                            <Form.Label >Отдел</Form.Label>
                            {selected === 5 ?
                                <Form.Select onChange={onChaneDepartment} className=''  defaultValue={userInfo.department.id}>
                                    {departments.map((d,i)=>
                                        <option key={i} value={d.id} >{d.name}</option>
                                    )}                                                                          
                                </Form.Select>
                            : <div className='fs-5' onClick={()=>setSelected(5)}>{userInfo.department.name}</div>}
                        </Form.Group>
                    </Col>
                </Row>
            </Container>
                </MenuLayout></>)
    }else {
        return(
            <MenuLayout>
            <Loader />
            </MenuLayout>
        )
    }

    
}