import React, {  useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build"
import { Button } from 'react-bootstrap';

export function TextEditor(props){

    const [text,setText] = useState(props.text);
    const [editor,setEditor] = useState();
    const saveMessage=()=>{
        props.onTextSave(text);
        setText('');
        if(editor!==undefined){
            editor.setData('');
        }
    }

    return(<>
             <div className='text-end  p-1 bg-white'>
                    
        
            <div className={props.className} style={props.style} >
                    <CKEditor                       
                        editor={ Editor }
                        config={ {toolbar:[
                            'heading',
                            '|',
                            'bold',
                            'italic',
                            'link',
                            'bulletedList',
                            'numberedList',
                            '|',
                            'outdent',
                            'indent',
                            '|',
                            'blockQuote',
                            'insertTable',
                            'undo',
                            'redo',
                            'codeBlock',
                            'code',
                            'fontSize',
                            'fontFamily',
                            'alignment'
                        ]
                    }}
                        data={props.text}
                        onChange={(event,editor)=>setText(editor.getData())}
                        onReady={(e)=>{
                            setEditor(e);
                            e.editing.view.change( writer => {
                                writer.setStyle("height",props.height,e.editing.view.document.getRoot()) 
                            })
                         }}                         
                    />
                          
            </div>
                <Button variant='outline-success' className='mt-2 m-1' onClick={saveMessage} disabled={props.disabled}><i className="bi bi-send me-2"></i>Отправить</Button>
            </div>
            
            
        
    </>)
}
export function TextEditorSimple(props){

    const [editor,setEditor] = useState();    
    return(<>
             <div className='text-end  p-1 bg-white'>
                    
        
            <div className={props.className} style={props.style} >
                    <CKEditor                       
                        editor={ Editor }
                        config={ {toolbar:[
                            'heading',
                            '|',
                            'bold',
                            'italic',
                            'link',
                            'bulletedList',
                            'numberedList',
                            '|',
                            'outdent',
                            'indent',
                            '|',
                            'blockQuote',
                            'insertTable',
                            'undo',
                            'redo',
                            'codeBlock',
                            'code',
                            'fontSize',
                            'fontFamily',
                            'alignment'
                        ]
                    }}
                        data={`<p>${props.text}</p>`}
                        onChange={(event,editor)=>props.onChange(editor.getData())}
                        onReady={(e)=>{
                            setEditor(e);
                            e.editing.view.change( writer => {
                                writer.setStyle("height",props.height,e.editing.view.document.getRoot()) 
                            })
                         }}                         
                    />
                          
            </div>                                               
            </div>
    </>)
}