import React, { useEffect, useState } from 'react';
import {
    Table,
    Row,
    Button,
    Form,
    Tab, Tabs,
    FormControl
} from 'react-bootstrap';

import { DateFilter, PagenationPanel } from "../../common/tables"
import { GetHttp, PostHttp } from '../../assets/connector';
import Loader from '../spinner';
import { Instrument } from '../../pages/skladsystem/instrument';

export function InstrumentCatalog({ endpoint, onHide, customer }) {
    const [categories, setCategories] = useState({ content: [] });
    const [instrumentContainers, setnstrumentContainers] = useState([])
    const [selectedConteiner, setSelectedContainer] = useState(0);
    const [instrument, setInstrument] = useState({ content: [] })
    const [selectedCategory, setSelectedCategory] = useState(-1);
    const [pageCategory, setPageCategory] = useState(0);
    const [pageInstument, setPageInstrument] = useState(0);
    const [nav, setNav] = useState([-1])
    const [showStats,setShowStats] = useState();


    useEffect(() => {
        fetchContainers();
    }, [])

    function fetchContainers() {

        GetHttp(`/endpoints/${endpoint.remoteService}/request/instcontainer?customer=${customer}`)
            .then(res => {
                if (res.ok) {
                    res.json().then(json => {
                        setnstrumentContainers(json);
                    })

                }
            })
    }
    function onSelectInstrumentContainer(id) {
        setSelectedContainer(id)
        if (id !== "-1") {
            GetHttp(`/endpoints/${endpoint.remoteService}/request/category?customer=${customer}&container=${id}&page=0`)
                .then(res => {
                    if (res.ok) {
                        res.json().then(json => {
                                setCategories(json);
                                setInstrument({ content: [] })
                        })

                    }
                })

        } else {
            setCategories({ content: [] })
            setInstrument({ content: [] })
            setSelectedCategory(0);
            setSelectedContainer(0);

        }
        setNav([-1])
    }

    const onSelectInstrumentCategory = (page, category) => {
        if (category !== nav[nav.length - 1]) { nav.push(category); }
        GetHttp(`/endpoints/${endpoint.remoteService}/request/instruments?page=${page}&size=20&category=${category}&customer=${customer}&container=${selectedConteiner}`)
            .then(res => {
                if (res.ok) {
                    res.json().then(json => {
                        setInstrument(json);
                        setPageInstrument(page);
                    })

                }
            })
    }

    const onClose = () => {
        setInstrument({ content: [] })
        onHide();
    }

    const onSelectCategory = (page, category) => {
        nav.push(category);
        GetHttp(`/endpoints/${endpoint.remoteService}/request/category?page=${page}&size=20&category=${category}&customer=${customer}&container=${selectedConteiner}`)
            .then(res => {
                if (res.ok) {
                    res.json().then(json => {
                        setCategories(json);
                        setInstrument({content:[]})
                        setPageInstrument(page);
                    })

                }
            })
    }

    const onBackNav = () => {
        if (nav[nav.length - 1] !== -1) {
            nav.pop();
            GetHttp(`/endpoints/${endpoint.remoteService}/request/category?category=${nav[nav.length - 1]}&customer=${customer}&container=${selectedConteiner}&ize=20`)
                .then(res => {
                    if (res.ok) {
                        res.json().then(json => {
                            
                                setCategories(json);
                                setInstrument({ content: [] });
                                setPageCategory(0);
                                setSelectedCategory(nav[nav.length - 1])
                        })

                    }
                })            
        }
    }
    const fields = [{ name: "id", sort: "id" }, { name: "Наименование", sort: "name" }, { name: "Инструмент", sort: "onlyInstrument" }, { name: "Элементов", sort: "childrenElementCounter" }]
    const tableCatigory =
        <Table className="mt-1" >
            <thead>
                <tr>
                    {fields.map((f, i) => <th>{f.name}</th>)}
                </tr>
            </thead>
            <tbody>
                {categories.content.map(e => <tr>
                    <td>{e.id}</td>
                    <td onClick={() => { e.onlyInstrument ? onSelectInstrumentCategory(0, e.id) : onSelectCategory(0, e.id); }} className="link-primary" style={{ cursor: "pointer" }}>{e.name}</td>
                    <td>{e.onlyInstrument ? "Да" : "Нет"}</td>
                    <td>{e.childrenElementCounter}</td>
                </tr>

                )}
            </tbody>

        </Table>
    const fields2 = [{ name: "id", sort: "id" }, { name: "Наименование", sort: "name" }, { name: "Модель", sort: "model" }, { name: "Доступно", sort: "id" }, { name: "Всего", sort: "totalCount" }]
    const tableInstrument =
        <Table className="mt-1" >
            <thead>
                <tr>
                    {fields2.map((f, i) => <th>{f.name}</th>)}
                </tr>
            </thead>
            <tbody>
                {instrument.content.map(e => {
                    const avalable = e.instrumentCounters !== null && e.instrumentCounters !== undefined ? e.instrumentCounters.avalable : 0;
                    return (<tr>
                        <td>{e.id}</td>
                        <td onClick={() => setShowStats(e.id)} className="link-primary" style={{ cursor: "pointer" }}>{e.name}</td>
                        <td>{e.model}</td>
                        <td>{avalable}</td>
                        <td>{e.totalСount}</td>
                    </tr>)
                }
                )}
            </tbody>
        </Table>



    return (<>
        <Tabs defaultActiveKey="general" id="noanim-tab" mountOnEnter unmountOnExit>
            <Tab eventKey="general" title="Главная">
                
                <div className='d-flex'>
                    <Form.Select onChange={(e) => onSelectInstrumentContainer(e.target.value)}>
                        <option value="-1" selected={selectedCategory == -1}>Выбор обьекта или склада</option>
                        {instrumentContainers.map((v) =>
                            <option value={v.id} selected={selectedConteiner == v.id}>{v.name}</option>
                        )}
                    </Form.Select>
                    <div>
                        <Button onClick={onBackNav} disabled={nav[nav.length - 1] === -1}>Назад</Button>
                    </div>
                </div>
                {showStats ?
                    <Instrument endpoint={endpoint} onHide={()=>setShowStats()} instrumentid={showStats} />
               :
                <div className="text-dark text-center h5 p-2">Не выбран инструмент</div>}
                {instrument.content.length > 0 ? tableInstrument : tableCatigory}
            </Tab>
            <Tab eventKey="jurnal" title="Журнал">
                <Report customer={customer} endpoint={endpoint} />
            </Tab>
            <Tab eventKey="writeoff" title="Списания">
                <Writeoff customer={customer} endpoint={endpoint} />
            </Tab>
            <Tab eventKey="arrival" title="Поступдения">
                <Arrival customer={customer} endpoint={endpoint} />
            </Tab>
            <Tab eventKey="inhand" title="На руках">
                <Inhand customer={customer} endpoint={endpoint} />
            </Tab>
            <Tab eventKey="summary" title="Склады/Обьекты">
                <InstrumentContainers customer={customer} endpoint={endpoint} />
            </Tab>
            <Tab eventKey="containers" title="Суммарно">
                <Summary customer={customer} endpoint={endpoint} />
            </Tab>

        </Tabs>
        <div className="text-center my-2">
            <Button id={-1} onClick={onClose} className="mr-1">Закрыть</Button>
        </div>
    </>)
}

function Report({ endpoint, customer }) {

    const [repors, setRepors] = useState({ content: [], pageable: {} })
    const [search, setSearch] = useState();

    useEffect(() => {
        fetch(0);

    }, [])

    function fetch(page) {
        setRepors()
        PostHttp(`/endpoints/${endpoint.remoteService}/request/jurnal?size=10&page=${page}`, { customer: customer, search: search })
            .then(res => {
                if (res.ok) {
                    res.json().then(json => {
                        setRepors(json);
                    })

                }
            })

    }




    if (repors) {

        return (<>
            <div className='mt-1'>
                <div className='ms-auto d-flex col-4'>
                    <FormControl onChange={(e) => setSearch(e.target.value)} />
                    <Button className='mx-2' size="sm" onClick={() => fetch(0)}>Поиск</Button>
                </div>
            </div>

            <Table className="mt-1" >
                <thead>

                </thead>
                <tbody>
                    {repors.content.map(e => {

                        return (<tr>
                            <td>{e.id}</td>
                            <td>{e.recipient.firstname + " " + e.recipient.surname}</td>
                            <td>{e.instrument.name + " " + e.instrument.model}</td>
                            <td>{e.instrumentTransferAction === "issue_inst" ? "Выдача" : "Возрат"}</td>
                            <td>{e.count}</td>
                            <td>{new Date(e.timestamp).toLocaleString()}</td>
                        </tr>)

                    })}
                </tbody>
            </Table>
            <PagenationPanel page={repors} nextPage={(p) => fetch(p)} />
        </>)
    } else {
        return <Loader />
    }
}

function Inhand({ endpoint, customer }) {

    const [inhand, setInhand] = useState({ content: [], pageable: {} })
    function fetch(page) {
        GetHttp(`/endpoints/${endpoint.remoteService}/request/inhand?page=${page}&customer=${customer}&sort=timestamp,DESC`)
            .then(res => {
                if (res.ok) {
                    res.json().then(json => {
                        setInhand(json);
                    })

                }
            })
        // GetHttpParams(_URL_INSTRUMENT+"inhand",{page:page,customer:props.customer})
        // .then(e=>setInhand(e))
    }
    useEffect(() => { fetch(0) }, [])

    const fields = [{ name: "Инструмент", sort: "instrument_name" }, { name: "Кол-во", sort: "" }
        , { name: "Получатель", sort: "" }
        , { name: "Резерв", sort: "reserved" }, { name: "Дата", sort: "timestamp" }]

    return (<>
        <Table>
            <thead></thead>
            <thead>
                {inhand.content.map(e => {
                    return (<tr>
                        <td>{e.instrument !== null ? e.instrument.name + " " + e.instrument.model : "Ошибка!"}</td>
                        <td>{e.count}</td>
                        <td>{e.recipient.firstname + " " + e.recipient.surname}</td>
                        <td>{e.reserved}</td>
                        <td>{new Date(e.timestamp).toLocaleString()}</td>
                    </tr>)
                })}
            </thead>
        </Table>
        <PagenationPanel page={inhand} nextPage={(p) => fetch(p)} />
    </>)
}

function Arrival({ customer, endpoint }) {
    const [arrival, setArrival] = useState({ content: [], pageable: {} })
    const [dateFrom, setDateFrom] = useState();
    const [dateTo, setDateTo] = useState();
    const backdays = 4;
    function fetch(page) {
        GetHttp(`/endpoints/${endpoint.remoteService}/request/strumentarrival?page=${page}&customer=${customer}&size=10`)
            .then(res => {
                if (res.ok) {
                    res.json().then(json => {
                        setArrival(json);
                    })

                }
            })
        // GetHttpParams(_URL_INSTRUMENT_ARRIVAL,{page:page,customer:props.customer,datefrom: dateFrom,
        // 	dateto: dateTo})
        // .then(e=>setArrival(e))
    }
    useEffect(() => { fetch(0) }, [])
    const handleFilter = (dateF, dateT) => {
        if (dateF !== '' && dateT !== '') {

            setDateTo(dateT + " 23:59:00");
            setDateFrom(dateF + " 00:00:00");
        } else {
            setDateTo(null)
            setDateFrom(null)
        }
        fetch(0);
    }
    
    return (<>
        <div className='mt-1'>
            <DateFilter show={true} onFilter={handleFilter} backdays={backdays} />
        </div>
        <Table>
            <thead></thead>
            <tbody>
                {arrival.content.map(e => {
                    return (<tr>
                        <td>{e.comment}</td>
                        <td>{e.instrumentArrival.length}</td>
                        <td>{e.uncompleted ? "Нет" : "Да"}</td>
                        <td>{new Date(e.docDate).toLocaleString()}</td>
                    </tr>)
                })}
            </tbody>
        </Table>
        <PagenationPanel page={arrival} nextPage={(p) => fetch(p)} />
    </>)

}
function Writeoff({ customer, endpoint }) {
    const [writeoff, setWriteoff] = useState({ content: [], pageable: {} })
    const [dateFrom, setDateFrom] = useState();
    const [dateTo, setDateTo] = useState();
    const backdays = 4;
    function fetch(page) {
        GetHttp(`/endpoints/${endpoint.remoteService}/request/writeoff?page=${page}&customer=${customer}`)
            .then(res => {
                if (res.ok) {
                    res.json().then(json => {
                        setWriteoff(json);
                    })

                }
            })
        // GetHttpParams(_URL_WRITEOFF,{page:page,customer:props.customer,datefrom: dateFrom,
        // 	dateto: dateTo})
        // .then(e=>setWriteoff(e))
    }
    useEffect(() => { fetch(0) }, [])

    const handleFilter = (dateF, dateT) => {
        if (dateF !== '' && dateT !== '') {

            setDateTo(dateT + " 23:59:00");
            setDateFrom(dateF + " 00:00:00");
        } else {
            setDateTo(null)
            setDateFrom(null)
        }
        fetch(0);
    }
    const fields = [{ name: "Комментарий", sort: "instrument_name" }
        , { name: "Завершенно", sort: "" }
        , { name: "Сотрудник", sort: "reserved" }, { name: "Дата", sort: "timestamp" }]
    return (<>
        <div className='mt-1'>
            <DateFilter show={true} onFilter={handleFilter} backdays={backdays} />
        </div>
        <Table>
            <thead>
                <tr>
                    {fields.map((f, i) => <th key={i}>{f.name}</th>)}
                </tr>
            </thead>
            <tbody>
                {writeoff.content.map(e => {
                    return (<tr>
                        <td>{e.comment}</td>
                        <td>{e.uncompleted ? "Нет" : "Да"}</td>
                        <td>{e.account_str}</td>
                        <td>{new Date(e.timestamp).toLocaleString()}</td>
                    </tr>)
                })}
            </tbody>
        </Table>
        <PagenationPanel page={writeoff} nextPage={(p) => fetch(p)} />
    </>)
}

function Summary({ customer, endpoint }) {
    const [summary, setSummary] = useState({ content: [], pageable: {} })


    function fetch(page) {
        GetHttp(`/endpoints/${endpoint.remoteService}/request/summary?page=${page}&customer=${customer}&sort=`)
            .then(res => {
                if (res.ok) {
                    res.json().then(json => {
                        setSummary(json);
                    })

                }
            })
        
    }
    useEffect(() => { fetch(0) }, [])

    const [showStats, setStats] = useState();

    if (showStats) {
        return (<Instrument endpoint={endpoint} instrumentid={showStats} onHide={()=>setStats()}/>)
    } else {

        return (<>

            <Table>
                <thead>
                    <tr>
                        <th>id</th>
                        <th>Название/модель</th>
                        <th>Удален</th>
                        <th>Всего</th>
                        <th>Списано</th>
                        <th>Поступило</th>
                    </tr>
                </thead>
                <tbody>
                    {summary.content.map(e => {
                        return (<tr>
                            <td onClick={()=>setStats(e.instrument.id)} className='text-primary'>{e.instrument.id}</td>
                            <td>{e.instrument.name + " " + e.instrument.model}</td>
                            <td>{e.instrument.deleted ? "Да" : "Нет"}</td>
                            <td>{e.instrument.totalСount}</td>
                            <td>{e.writeoffTotalCount}</td>
                            <td>{e.arrivalTotalCount}</td>
                        </tr>)
                    })}
                </tbody>
            </Table>
            <PagenationPanel page={summary} nextPage={(p) => fetch(p)} />
        </>)
    }
}
function InstrumentContainers({ customer, endpoint }) {
    const [ic, setIC] = useState([]);

    function fetch() {
        GetHttp(`/endpoints/${endpoint.remoteService}/request/instcontainer?customer=${customer}`)
            .then(res => {
                if (res.ok) {
                    res.json().then(json => {
                        setIC(json);
                    })

                }
            })
        // GetHttpParams(_URL_INSTRUMENT_CONTAINER , { customer: props.customer })
        // 	.then(ic => {
        // 		setIC(ic);
        // });
    }

    useEffect(() => { fetch() }, [])

    return (<>
        <Row className=" m-0">
            <Table size="sm" striped bordered hover>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Название </th>
                        <th>Примечание</th>
                        <th>Выдано инструмента</th>
                        <th>Кол-во инструмента</th>

                    </tr>
                </thead>
                <tbody>

                    {ic.map((v, k) => {
                        return <>
                            <tr>
                                <td>{k + 1}</td>
                                <td>{v.name}</td>
                                <td>{v.description}</td>
                                <td>{v.instrumentIssued}</td>
                                <td>{v.instrumentCount}</td>
                            </tr>
                        </>
                    })}

                </tbody>

            </Table>
        </Row>
    </>)
}