import React, {useState} from 'react';
import {  Button,Pagination,Form } from 'react-bootstrap';

export function GetMore({pageable, onClick,disabled}){
    if(!pageable.last){
        return (<div className="text-center"><Button disabled={disabled} variant="outline-success" onClick={onClick}>Показать еще</Button></div>)
    }
}

export function PagenationPanel({page,nextPage}){
    let pagination_item = [];
    for (let a = page.pageable.pageNumber - 2; a < page.pageable.pageNumber + 3; a++) {
        if (page.totalPages > a) {
            pagination_item[a] = <Pagination.Item id={a} active={page.pageable.pageNumber === a} onClick={() => { nextPage(a); }}>{a + 1}</Pagination.Item>
        }
    }
    let next = page.pageable.pageNumber + 1 < page.totalPages ? page.pageable.pageNumber + 1 : page.pageable.pageNumber;
    let priveous = page.pageable.pageNumber - 1 >= 0 ? page.pageable.pageNumber - 1 : 0;
    const pagination = <Pagination className="justify-content-center" size={page.size !== undefined ? page.size : ''}>
        <Pagination.First onClick={() => { nextPage(0); }} />
        <Pagination.Prev onClick={() => { nextPage(priveous); }} />
            {pagination_item}
        <Pagination.Next onClick={() => { nextPage(next); }} />
        <Pagination.Last onClick={() => { nextPage(page.totalPages - 1); }} />
    </Pagination>

    return(<>{pagination}</>)
}

export function SearchForm({onSearch}){
    const [search,setSearch] = useState('');

    return(<><div className='ms-auto d-flex'>
        <Form.Control type="search" placeholder="Поиск" className="me-2" aria-label="Search" onChange={(e)=>setSearch(e.target.value)}/>
        <Button variant="link" onClick={()=>onSearch(search)}><i className="bi bi-search text-dark"></i></Button>
        </div>
        </>)
    }

export function DateFilter(){

    return(<div>Date Filter</div>)
}