import React, {useEffect, useState} from 'react';
import { MenuLayout } from '../common/layout';
import { Alert, Button, ListGroup } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { DeleteHttp, GetHttp, PostHttp } from '../assets/connector';
import { checkConnection } from '../common/common';
import Loader from '../common/spinner';

export function Channels(){

    const [channels,setChannels] = useState();
    const [message,setMessage] = useState();
    const nav = useNavigate();
    function onConnect(type){
        PostHttp("/channel",{channelType:type})
        .then((res)=>{
            if(res.ok){
            fetchChannels();
            }else {
                res.json().then(json=>setMessage(json.message));
            }
        });
    }

    function fetchChannels(){
        GetHttp("/channel")
        .then(res=>{
            checkConnection(res).then(json=>setChannels(json));
            })        
    }

    function onDisconnect(id){
        DeleteHttp(`/channel/${id}`)
        .then(e=>fetchChannels());
    }

    useEffect(()=>fetchChannels(),[]);

    function ChannelNewBlock(){


        return(<div className='bg-white border rounded m-1' style={{width:"18em",height:"15em"}}>
           
            <ListGroup variant="flush" className='text-center'>
                <ListGroup.Item>
                    <span className='fs-5'>Новый канал</span>
                </ListGroup.Item>
                <ListGroup.Item>
                    <Button variant='link p-0' onClick={()=>onConnect("telegrambot")}>Подключить Telegram bot</Button>
                </ListGroup.Item>
                <ListGroup.Item>
                    <Button variant='link p-0'onClick={()=>onConnect("form")} >Подключить простую форму</Button>
                </ListGroup.Item>
                <ListGroup.Item>
                    <Button variant='link p-0'onClick={()=>onConnect("email")} >Подключить Email</Button>
                </ListGroup.Item>
                
            </ListGroup>
                
        </div>)
    }

    function ChannelConnectedBlock({channel,id}){


        return(<div className='bg-white text-center border rounded m-1' style={{width:"18em",height:"15em"}}>
            <div className='p-1 w-100 h-100 mt-2 d-flex flex-column'>
                <span className='p-2 mb-1 fs-5'>{channel.name}</span>
                <span className='badge text-bg-success '>{channel.channelType}</span>
                <div className='my-2 text-decoration-underline'>{channel.enabled?'Активен':'Не активен'}</div>
                <div className='my-2'><Button variant="link" onClick={()=>nav("/ch/channel",{state:{id:channel.id}})}>Настроить</Button></div>
                <div className='mt-auto'><Button variant="link"><i className="bi bi-power fs-4 text-danger" onClick={()=>onDisconnect(channel.id)}></i></Button></div>
            </div>
        </div>)
    }

    if(channels){
    return(<>
        <MenuLayout message={message}>
            <div className=' p-1'>
                <div className='d-flex flex-wrap'>
                    <ChannelNewBlock />
                    {channels.content.map((ch,i)=>{
                        return <ChannelConnectedBlock key={i} channel={ch} id={i+1}/>
                    })}
                </div>
            </div>
        </MenuLayout></>)
    
    }else {
        return(
            <MenuLayout>
                <Loader />
            </MenuLayout>
        )
    }
}