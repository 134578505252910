import React, {useEffect, useRef, useState} from 'react';
import { MenuLayout } from '../common/layout';
import { GetHttp } from '../assets/connector';
import Loader from '../common/spinner';
import { Image } from 'react-bootstrap';
import { GetMore } from '../common/tables';

export function Files(){

    const [files,setFiles] = useState({content:[],page:0,numberOfElements:0,fileSize:0})
    const [lockButton,setlockButton] = useState(false);

    function fetchFiles(page){
        setlockButton(true)
        GetHttp("/file/all?page="+page)
        .then(res=>{
            if(res.ok){
                res.json().then(json=>{                
                    for(let i=files.content.length-1;i>-1;i--){
                        json.content.unshift(files.content[i]);                                     
                    }   
                    json.numberOfElements= json.numberOfElements + files.numberOfElements;
                    json.fileSize=json.content.reduce((sum,item)=>sum+item.fileSize,0);
                    setFiles(json)
                    setlockButton(false);
                });
            }
        })
    }

    useEffect(()=>fetchFiles(0),[]);

    if(files){
        const tSize = files.fileSize>0? (files.fileSize/1024).toFixed(2): files.fileSize;
    return(<>
        <MenuLayout>
            <div className='bg-white p-2'>
                <div>{`Количество файлов: ${files.numberOfElements} общий размер: ${tSize } kb`}</div>
                <div className='d-flex flex-wrap'>
                    {files.content.map((e,i)=>{
                       return( <div key={i} className='m-1 p-1 border'>
                              <FieItem file={e}/>                              
                            </div>)    
                    })}
                    
                </div>
                <div className='my-2'>
                    <GetMore onClick={()=>{fetchFiles(files.number+1)}} pageable={files} disabled={lockButton}/>
                </div>
            </div>
        </MenuLayout>    
        
    </>)
    }else {

        return(
            <MenuLayout>
                <Loader/>   
            </MenuLayout>
        )
    }
}

function FieItem({file}){
    const dw = useRef();
    const download = ()=>{
        GetHttp(`/file/uuid/${file.uuid}`)
        .then(res=>{
            if(res.ok){
                res.blob().then(b=>{
                    
                    const filename = res.headers.get('content-disposition').split(";")[1].split("?")[3];
                    
                    dw.current.href = window.URL.createObjectURL(b);
                    dw.current.download=filename;
                    dw.current.click();
                    
                })
                
            }
        })
    }

    if(file.extention === "jpg" || file.extention ==="png" || file.extention ==="jpeg"){
        return(
        <div className='position-relative'>
            <Image className=''  src={"data:image/png;base64,"+file.data} style={{maxHeight:"200px",width:"200px"}}/>
            <div className='text-center'>{(file.fileSize/1024).toFixed(1)+" kb"}</div>
        </div>)
    }else {
        
        return(<>
            <div className='position-relative me-2' style={{width:"200px",height:"200px"}}>
            <div className="position-absolute top-0 start-50 translate-middle-x"><i class="bi bi-file-earmark-text fs-1"></i></div>
            <div className='position-absolute top-50 start-50 translate-middle-x text-primary text-center' style={{cursor:'pointer'}}  onClick={download}>{`Скачать `+(file.fileSize/1024).toFixed(1)+` kb `}</div>
            <div className='position-absolute bottom-0 start-50 translate-middle-x'>{file.extention} </div>
            <a href="" hidden ref={dw} />
        </div>
        </>)
    }
}