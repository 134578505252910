import React, {useEffect, useState} from 'react';
import { MenuLayout } from '../common/layout';
import { Table,Image,Button,Form,Modal, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { GetHttp, PostHttp } from '../assets/connector';
import { SearchForm } from '../common/tables';
import {useResponseStatusCheck } from '../common/common';

export function Employees({menu}){

    const [employee,setEmployee] = useState({content:[]})
    const checkStatus = useResponseStatusCheck();
    const nav = useNavigate();

    function viewEmployee(employee){
        nav("/main/employee",{state:{employee:employee}});
    }

    

    function fetchEmployees(){
        GetHttp("/employee")
        .then(e=>{
            checkStatus(e.status);
            if(e.ok){
                e.json().then(json=>setEmployee(json));
            }
        });
        
    }

    function searchEmployees(search){
        PostHttp("/employee/search",{searchString:search?search:''})
        .then(e=>{
            checkStatus(e.status);
            if(e.ok){
                e.json().then(json=>setEmployee(json));
            }
        });
        
    }

    useEffect(()=>fetchEmployees(),[])

    function getStatus(status){
        switch(status){
            case 'active': return <span className='text-success'>Активен</span>
            case 'dismiss': return <span className='text-danger'>Уволен</span>;
            case 'invited': return <span className='text-primary'>Прилашение</span>;
            case 'blocked': return <span className='text-danger'>Заблокирован</span>;
        }

    }
    function TableItem({name,value}){

    return(<div className=''>
        <div className='fw-bold'>
            {name}
        </div>
        <div>
            {value}
        </div>
    </div>)
}

const [showDialog,setShowDialog] = useState(false);
const [search,setSearch] = useState('');


function AddDialog({show,onHide,onUpdate}){

    const [employee,setEmployee] = useState({email:'',firstName:''})
    const [message,setMessage] = useState(); 
    
    const onCommit=()=>{
            PostHttp("/employee",employee)
            .then((res)=>{
                if(res.ok){
                    onUpdate()
                }else {
                    res.json().then(json=>setMessage(json.message));
                }
            });
        
    }

    useEffect(()=>{setMessage();setEmployee({email:'',firstName:''})},[show])

    return (<Modal show={show} backdrop={true} onHide={onHide} >
        <Modal.Body>
            {message != undefined ? <Alert variant='danger'>{message}</Alert>:""}
            <Form.Group className="mb-3" >
                <Form.Label>Имя</Form.Label>
                <Form.Control type="text" onChange={(e)=>setEmployee({email:employee.email,firstName:e.target.value})} />
            </Form.Group>
            <Form.Group className="mb-3" >
                <Form.Label>Email </Form.Label>
                <Form.Control type="email" onChange={(e)=>setEmployee({email:e.target.value,firstName:employee.firstName})} />
            </Form.Group>
            <div className='text-end'>    
                <Button variant='outline-secondary' className='me-2' onClick={onHide}>Отмена</Button>    
                <Button variant='outline-success'  onClick={onCommit}>Пригласить</Button>            
            </div>
        </Modal.Body>
    </Modal>)
}

function AvatarTD({avatar,employee}){

    const [image,setImage] = useState();

    function fetchUserInfo(){
        if(avatar !=="/default-avatar.png" && avatar!=null){
        GetHttp("/file/uuid/" + avatar.split(".")[0]).then(e => {
            if (e.ok) {
                e.blob().then(b => setImage(URL.createObjectURL(b)));
            }
        })
        }else {
            setImage("/default-avatar.png")
        }
    }
    useEffect(()=>fetchUserInfo(),[])
    return(<>
                    <td style={{width:'100px',cursor:"pointer"}} onClick={()=>viewEmployee(employee)}>
                        <div>
                            <div><Image roundedCircle width="50" src={image}></Image></div>
                        </div>                                    
                    </td>
    
    </>)
}

    return(<>
        <MenuLayout menu={menu}>
                <div className='bg-white p-1'>
                <AddDialog show={showDialog} onHide={()=>setShowDialog(false)} onUpdate={()=>{fetchEmployees();setShowDialog(false)}}/>
                    <Table responsive>
                        <tbody>
                            <tr>
                                <td colSpan={4} className=''><Button variant="link" onClick={()=>setShowDialog(true)}>Пригласить</Button></td>
                                <td colSpan={2}><SearchForm onSearch={(s)=>searchEmployees(s)}/></td>
                            </tr>
                            {employee.content.map((em,i)=>{
                                
                                return(
                                    <tr key={i}>                                        
                                        <AvatarTD avatar={em.avatar} employee={em}/>
                                        <td>
                                            <TableItem name="ФИО" value={em.firstName +" "+em.lastName}/>
                                        </td>
                                        <td>
                                            <TableItem name="Email" value={em.email}/>
                                        </td>
                                        <td>
                                            <TableItem name="Зарегестрирован" value={new Date(em.regDate*1000).toLocaleString()}/>
                                        </td>
                                        <td>
                                            <TableItem name="Отдел" value={em.department.name}/>
                                        </td>
                                        <td>
                                            <TableItem name="Статус" value={getStatus(em.status)}/>
                                        </td>
                                    </tr>
                                )
                            })}
                            
                        </tbody>

                    </Table>

                </div>
            </MenuLayout></>)
}

