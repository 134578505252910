
export const _API_URL = "/api"


export const oidcSettings = {
    authority: process.env.REACT_APP_OIDC_authority,
    redirect_uri: process.env.REACT_APP_OIDC_redirect_uri,
    response_type: 'code',
    client_id: process.env.REACT_APP_OIDC_client_id,
    client_secret: process.env.REACT_APP_OIDC_client_secret,    
    scope:"openid",
    disablePKCE: false
};


function getTokenFromSession(){
    const oidcStorage = sessionStorage.getItem(`oidc.user:${oidcSettings.authority}:${oidcSettings.client_id}`)
    if (oidcStorage!==null) {
        const user = JSON.parse(oidcStorage); 
        return user.token_type+" "+user.access_token;
    }
    return "";
}

export async function GetHttp(url,param){
    if(param!== undefined){
        let e;
        for(let p in param){
            e===undefined?e="?":e=e+"&"
            e = e+p+"="+encodeURI(param[p]);
            
        }
        url = url+e;
    }
   return  await fetch(_API_URL+url,{headers:{Authorization: getTokenFromSession()}})
}

export async function DeleteHttp(url,param){
    if(param!== undefined){
        let e;
        for(let p in param){
            e===undefined?e="?":e=e+"&"
            e = e+p+"="+encodeURI(param[p]);
            
        }
        url = url+e;
    }
   return  await fetch(_API_URL+url,{method:'delete',headers:{Authorization: getTokenFromSession()}})
}

export async function PostHttp(url,body){
    
   return  await fetch(_API_URL+url,{method:'post',body:JSON.stringify(body),headers:{'Content-Type':"application/json",Authorization: getTokenFromSession()}})
}
export async function PutHttp(url,body){
    
    return  await fetch(_API_URL+url,{method:'put',body:JSON.stringify(body),headers:{'Content-Type':"application/json",Authorization: getTokenFromSession()}})
 }
export async function PostHttpMultipart(url,body){
    
    return  await fetch(_API_URL+url,{method:'post',body:body,headers:{Authorization: getTokenFromSession()}})
 }


