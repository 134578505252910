import { useEffect, useState } from "react";
import { GetHttp } from "../../assets/connector";
import Loader from "../../common/spinner";
import { Button } from "react-bootstrap";


export function Instrument({endpoint,instrumentid,onHide}){
    
    const [instrument,setInstrument] = useState();
    const [stats,setStats] = useState()


    function fetchInstrument(){
        
        GetHttp(`/endpoints/${endpoint.remoteService}/request/instrument?id=${instrumentid}`)
            .then(res => {
                if (res.ok) {
                res.json().then(json => {
                    setInstrument(json);
                })
                }
        })
    
    }

    function fetchStats(){
        GetHttp(`/endpoints/${endpoint.remoteService}/request/inststatistic?id=${instrumentid}`)
            .then(res => {
                if (res.ok) {
                res.json().then(json => {
                    setStats(json);
                })
                }
        })
    }

    useEffect(()=>{
        fetchInstrument()
        fetchStats();
    },[instrumentid])
    
    if(instrument && stats){
        return (
            <div className="bg-white p-2 my-2 text-dark">
                <div className="h4">{instrument.name + " " +instrument.model+" "+ instrument.vendor}</div>
                <div>Серийный: {instrument.serial}</div>
                    <div className="p-1 m-1">
                        <p>{`На руках:${stats.totalInhand}`}</p>
                        <p>{`Списано:${stats.totalWriteoff}`}</p>
                        <p>{`Выдано :${stats.totalIssued}`}</p>
                        <p>{`Возвратов:${stats.totalReturn}`}</p>
                        <p>{`Поступление:${stats.totalArrival}`}</p>
                        <p>{`В резерве:${stats.totalReserved}`}</p>
                        <p>{`Доступно:${stats.totalAvalable}`}</p>
                        
                    </div>
                <div className="text-center"><Button onClick={onHide}>ОК</Button></div>
            </div>
    )
    }else{
        return(<Loader />)
    }

}