import React, {useEffect, useState} from 'react';
import { MenuLayout } from '../common/layout';
import { Button,  Table,Form, Modal, ModalBody, ModalFooter} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import {getStatus,getPriority} from '../common/bid';
import { GetHttp } from '../assets/connector';
import Loader from '../common/spinner';
import { GetMore } from '../common/tables';
import {  useResponseStatusCheck } from '../common/common';


export function Request(){
    
    const nav = useNavigate();
    const [loader,setLoader] = useState(true);
    const [filter,setFilter] = useState({state:'all'})
    
    const addBid= ()=>{
        nav("/main/newbid")
    }
    
    const bidPerPage = 15;
    
    function ButtonBar({onSearch}){

        const [search,setSearch] = useState('');
        
        function selected(state){
            if(!state){
                return("text-decoration-none")
            }
                return ""
            
        }

        return(
            <tr>
                    <td colSpan={5}>
                        <div className='d-flex'>
                            <div className='d-flex'>
                                <Button variant="link"  onClick={addBid} >Новая заявка</Button>
                                <Button variant="link"  onClick={()=>setShowChannelSelect(true)} >Новая заявка из почты</Button>
                            </div>
                            <div className='d-flex ms-auto'>
                                <div className='d-flex'>
                                    <Form.Control type="search" placeholder="Поиск" className="me-2" onChange={(e)=>setSearch(e.target.value)}/>
                                    <Button size="sm" variant="link" onClick={()=>onSearch(search)}><i className="bi bi-search text-dark"></i></Button>
                                </div>
                                <div>
                                    <Button size="sm" className={`fs-5 ${selected(filter.state==="all")}`} variant='link' onClick={()=>fetchBids({size:bidPerPage,state:"all",search:""})}>Все</Button>
                                    <Button size="sm" className={`fs-5 ${selected(filter.state==="newbid")}`} variant="link text-success" onClick={()=>fetchBids({size:bidPerPage,state:"newbid",search:""})}>{`Новые`}</Button>
                                    <Button size="sm" className={`fs-5 ${selected(filter.state==="expired")}`} variant="link text-danger " onClick={()=>fetchBids({size:bidPerPage,state:"expired",search:""})}>{`Просроченные`} </Button>
                                    <Button size="sm" className={`fs-5 ${selected(filter.state==="complited")}`} variant="link text-secondary" onClick={()=>fetchBids({size:bidPerPage,state:"complited",search:""})}>{`Закрытые`} </Button>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>     
        )
    }
    const [bids,setBids] = useState({
        totalPages:2,        
        pageSize:5,        
        content:[]
    })

    const checkStatus = useResponseStatusCheck();

    function fetchBids(params){
        setFilter(params);
        GetHttp("/bid",{state:params.state,size:params.size,search:params.search}).then((b)=>{
            checkStatus(b.status);
            if(b.ok){
                b.json().then(json=>{
                    setBids(json);
                    setLoader(false);
                })
            }   
        })
        
    }
    const [sort,setSort] = useState("bid.date,DESC")
    const [showChannelSelect,setShowChannelSelect] = useState(false);

    useEffect(()=>{fetchBids({state:"all",size:bidPerPage,search:""})},[])        
    

    function TableItem({name,value}){


        return(<>
            <div className="p-2">
                <div className='fw-bold'>{name}</div>
                <div className='text-truncate'>{value}</div>
            </div>
        </>)
    }
    
    const navigate = useNavigate()
    function showRequest(v){
        navigate("/main/bid",{state:{bid:v}})
        
    }

        return(<>
            <MenuLayout >
                <ChannelSelect show={showChannelSelect} onHide={()=>setShowChannelSelect(false)}/>
                {loader 
                ?<Loader />
                :<>
                <div >
                <Table className='position-relative' responsive="xl">
                    <tbody>
                    <ButtonBar onSearch={(s)=>fetchBids({state:"all",search:s,size:bidPerPage,search:s})}/>
                        {bids.content.map((v,r)=>
                            <tr key={r}>
                                    <td style={{maxWidth:"60px"}}>
                                        <TableItem name="Тема" value={<Button variant='link' className={`p-0 text-decoration-none ${v.readStatus==null? 'fw-bold':""} `} onClick={()=>showRequest(v)}>{v.header}</Button>}/>
                                    </td>
                                    <td style={{maxWidth:"70px"}}>
                                        <TableItem name="Дата" value={new Date(v.date*1000).toLocaleString()}/></td>
                                    <td style={{maxWidth:"60px"}} >
                                        <TableItem name="Постановщик" value={<div style={{maxHeight:'40px'}}>{v.owner.firstName+" "+v.owner.lastName}</div>} /></td>
                                    <td style={{maxWidth:"20px"}} className='d-none d-lg-table-cell'>
                                        <TableItem name="Статус" value={getStatus(v.status)}/></td>
                                    <td style={{maxWidth:"20px"}} className='d-none d-lg-table-cell'>
                                        <TableItem name="Приоритет" value={getPriority(v.priority)}/>
                                    </td>
                                
                            </tr>    
                            )}
                    </tbody>
                    
                </Table>
                <GetMore onClick={()=>fetchBids({size:bids.size+5,state:filter.state,search:filter.search})} pageable={bids}/>
                </div></>}
                
            </MenuLayout>    
            
        </>)
}



function ChannelSelect({show,onHide}){

    const [channels,setChannels] = useState({content:[]})
    const nav =useNavigate();
    function fetchChannels(page){
        GetHttp("/channel?type=email&page="+page)
        .then(res=>{
            if(res.ok){
                res.json().then(json=>setChannels(json));
            }
            })        
    }

    useEffect(()=>{
        fetchChannels(0)
    },[])

    return(<>
        <Modal show={show} onHide={onHide}>
            <ModalBody>
                <Table>
                    <tbody>
                        {channels.content.map((e)=>{
                            return(
                            <tr>
                                <td>{e.name}</td>
                                <td><Button size="sm" onClick={()=>nav("/ch/mail",{state:{id:e.id,name:e.name}})}>Показать</Button></td>
                            </tr>)
                        })}
                        
                    </tbody>
                </Table>
                <GetMore  pageable={channels} onClick={()=>fetchChannels(channels.number+1)} />
                <ModalFooter>
                    <Button  onClick={onHide}>Закрыть</Button>
                </ModalFooter>
            </ModalBody>

        </Modal>
    </>)
}