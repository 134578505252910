import React, {useEffect, useReducer, useState} from 'react';
import { MenuLayout } from '../common/layout';
import { Table,Button,Modal, ModalTitle, ModalBody, ModalFooter,Form, Alert } from 'react-bootstrap';
import { DeleteHttp, GetHttp, PostHttp } from '../assets/connector';
import { SearchForm } from '../common/tables';

export function Contacts({menu}){

    const [message,setMessage ] = useState();
    return(<>
        <MenuLayout message={message}>
                <div className='bg-white p-1'>
                    <ContactTable setMessage={(e)=>setMessage(e)}/>
                </div>
        </MenuLayout>
        </>
    )
}

function TableCell({name,value}){
    return(
    <td>
        <div><b>{name}</b></div>
        <div>{value}</div>
    </td>)
}

function ContactTable({setMessage}){

    const[ contacts,setContacts] = useState({content:[]})
    const [showCreateDialog,setShowCreateDialog] = useState(false);

    function fetchContact(){
        GetHttp("/contact").then((res)=>{
            if(res.ok){
                res.json().then(json=>setContacts(json));
            }
        })
    }
    

    function searchContact(search){
        PostHttp("/contact/search",{searchString:search}).then((res)=>{
            if(res.ok){
                res.json().then(json=>{
                    setContacts(json);
                    document.querySelector("input[type='search']").value="";
                });
            }
        })
    }

    useEffect(()=>{
        fetchContact();
    },[])

    function onDelete(id){
        DeleteHttp(`/contact/${id}`).then((res)=>{
            if(res.ok){
                fetchContact();
            }else {
                res.json().then(json=>setMessage(json.message));
            }
        });
    }

    return(<>
        <CreateDialog show={showCreateDialog} onHide={()=>{setShowCreateDialog(false);fetchContact()}}/>
        <Table>
            <tbody>
                <tr>
                    <td colSpan={5}>
                        <div className='ms-auto d-flex'>
                            <div><Button variant="link" onClick={()=>setShowCreateDialog(true)}>Добавить</Button></div>
                            <SearchForm onSearch={(s)=>searchContact(s)} />
                        </div>
                    </td>
                </tr>
                {contacts.content.map((c,i)=>
                <tr key={i}>
                    <TableCell name="Имя" value={c.firstName+" "+c.surName}/>
                    <TableCell name="Организация" value={c.orgName}/>                    
                    <TableCell name="Email" value={c.email}/>
                    <TableCell name="Telegram" value={c.telegram}/>
                    <td>
                        <div></div>
                        <div className='mt-2'><Button variant="danger" size="sm" onClick={()=>onDelete(c.id)}> Х</Button></div>
                    </td>                   
                </tr>)}
            </tbody>

        </Table>
    </>)
}

function CreateDialog({show,onHide}){

    function reducer(state,act){
        if(act.name=="init"){
            return({firstName:"",surName:"",description:"",phone1:"",phone2:"",email:""});
        }else {
            state[act.name]= act.val;
            return state;
        }
        
    }
    const [contact,setContact ] = useReducer(reducer,{firstName:"",surName:"",description:"",phone1:"",phone2:"",email:""});
    const [message,setMessage ] = useState();

    const handleSend=()=>{
        
            PostHttp("/contact",contact).then((res)=>{
                if(res.ok){
                    onHide();
                }else {
                    res.json().then(json=>setMessage(json.message));
                }   
                
            });
        
    }

    useEffect(()=>{setMessage();setContact({name:"init"})},[show]);

    return(
    <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
            <ModalTitle>Добавить контакт</ModalTitle>
        </Modal.Header>
            <ModalBody>
                {message !=undefined ? <Alert variant="danger">{message}</Alert>:""}
                <Form.Group className="mb-3" >
                    <Form.Label>Имя (Оязательно)</Form.Label>
                    <Form.Control type="text"  onChange={(e)=>{setContact({name:'firstName' , val:e.target.value})}}/>            
                </Form.Group>
                <Form.Group className="mb-3" >
                    <Form.Label>Фамилия</Form.Label>
                    <Form.Control type="text"  onChange={(e)=>{setContact({name:'surName' , val:e.target.value})}} />            
                </Form.Group>
                <Form.Group className="mb-3" >
                    <Form.Label>Организация</Form.Label>
                    <Form.Control type="text"  onChange={(e)=>{setContact({name:'orgName' , val:e.target.value})}} />            
                </Form.Group>
                <Form.Group className="mb-3" >
                    <Form.Label>Описание</Form.Label>
                    <Form.Control type="text"  onChange={(e)=>{setContact({name:'description' , val:e.target.value})}}/>            
                </Form.Group>
                <Form.Group className="mb-3" >
                    <Form.Label>Номер телефона рабочий</Form.Label>
                    <Form.Control type="tel"  onChange={(e)=>{setContact({name:'phone' , val:e.target.value})}}/>            
                </Form.Group>                
                <Form.Group className="mb-3" >
                    <Form.Label>Номер телефона личный</Form.Label>
                    <Form.Control type="tel"  onChange={(e)=>{setContact({name:'phone1' , val:e.target.value})}}/>            
                </Form.Group>                
                <Form.Group className="mb-3" >
                    <Form.Label>Email (Не может повотряться)</Form.Label>
                    <Form.Control type="email"  onChange={(e)=>{setContact({name:'email' , val:e.target.value})}}/>            
                </Form.Group>
                
            </ModalBody>    
        <ModalFooter>
            <Button onClick={onHide}>Отмена</Button>
            <Button variant='success' onClick={handleSend}>Добавить</Button>
        </ModalFooter>
    </Modal>)
}
            