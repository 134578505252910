import React, { useEffect, useState } from 'react';
import { MenuLayout } from '../common/layout';
import { Container, Table, Image, Button, Form, InputGroup,Alert } from 'react-bootstrap';
import { DeleteHttp, GetHttp, PostHttp, PostHttpMultipart, _API_URL } from '../assets/connector';
import { FileuploadPopup } from '../common/files';

export function Departments({menu}) {

    const [departments, setDepartments] = useState([]);
    const [message,setMessage] = useState();
    function fetchDepartmens() {
        GetHttp("/department").then((d) => {
            if (d.ok) {
                d.json().then(j => setDepartments(j))

            }
        });
    }
    useEffect(() => fetchDepartmens(), [])


    const onAddDepartment = () => {
        PostHttp("/department", { name: "Новый отдел" })
            .then((res) => {
                if(res.ok){
                    setMessage()
                    fetchDepartmens();
                }else {
                    res.json().then(json=>setMessage(json.message))
                }
            })
    }

    return (<>
        <MenuLayout menu={menu}>
            <Container className='bg-white p-2' fluid>
                {message? <Alert variant='danger'>{message}</Alert> : ""}
                <Table responsive>
                    <tbody>
                        <tr>
                            <td colSpan={6} className=''><Button variant="link" onClick={onAddDepartment}>Добавить</Button></td>
                        </tr>
                        {departments.map((em, i) => {
                            return (<TD dp={em} key={i} onUpdate={() => fetchDepartmens()} setMessage={(e)=>setMessage(e)}/>)
                        })}
                    </tbody>
                </Table>
            </Container>
        </MenuLayout>
    </>)
}

function TD({ dp, onUpdate,setMessage }) {

    const [showUpload, setShowUpload] = useState();

    function uploadAvatar(file, id) {
        const fd = new FormData();
        fd.append("file", file.target.files[0]);
        PostHttpMultipart(`/department/${id}/avatar`, fd)
            .then(() => { onUpdate(); setShowUpload(); })
    }
    function onDeleteDepartment(id) {
        DeleteHttp("/department", { id: id })
            .then(() => {
                onUpdate();
                setMessage();
            })
    }

    function EditableField({ defaultValue, onUpdate }) {
        const [text, setText] = useState(defaultValue);

        return (<div className='d-flex'>
            <InputGroup className="mb-3">
                <Form.Control defaultValue={text} onChange={(r) => setText(r.target.value)} />
                <i className="bi bi-check-circle fs-3 text-success ms-2" onClick={() => onUpdate(text)}></i>
                <i className="bi bi-x-circle fs-3 text-danger ms-2" onClick={() => setEditName(0)}></i>
            </InputGroup>
        </div>)
    }
    const [src_img, setSrc_img] = useState();

    useEffect(() => {
        if (dp.image !== null) {
            GetHttp("/file/uuid/" + dp.image.split(".")[0]).then(e => {
                if (e.ok) {
                    e.blob().then(b => setSrc_img(URL.createObjectURL(b)));
                }
            })
        } else {
            setSrc_img("/default-avatar.png")
        }
    }, [dp.image])

    const [editName, setEditName] = useState(0);

    function update(text) {
        if(dp.name !== text){
        PostHttp("/department/update", { id: editName, name: text })
            .then((res) => {
                if(res.ok){
                    setMessage()
                    onUpdate();
                }else {
                    res.json().then(json=>setMessage(json.message));
                }
                
            });
        }
        setEditName(0);
    }

    return (<>
        <tr >
            <td style={{ width: '100px', cursor: "pointer" }}>
                <div className='text-center'>
                    <FileuploadPopup show={showUpload === dp.id} onChange={(e) => { uploadAvatar(e, dp.id); }} onHide={() => setShowUpload(0)} />
                    <Image roundedCircle width={46} height={46} src={src_img} onClick={() => setShowUpload(dp.id)}></Image>
                </div>
            </td>
            <td className="align-middle">
                {editName === dp.id
                    ? <EditableField defaultValue={dp.name} onUpdate={(text) => update(text)} />
                    : <div onClick={() => setEditName(dp.id)}>{dp.name}</div>}
            </td>
            <td className="align-middle" style={{ width: '100px' }}>
                <span className='fw-bold'>Сотрудники:</span>{dp.employeesCount}
            </td>
            <td className="align-middle" style={{ width: '100px' }}>
                <Button disabled={dp.employeesCount !== 0 || dp.defaultDepartment} variant="link" className='text-danger' onClick={() => onDeleteDepartment(dp.id)}>Удалить</Button>
            </td>
        </tr>
    </>)
}

