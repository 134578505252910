import React, { forwardRef, useEffect, useState } from 'react';
import { MenuLayout } from '../common/layout';
import { Button, Table,Col, ListGroup } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import {useResponseStatusCheck } from '../common/common';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Bar } from 'react-chartjs-2';
import { GetHttp } from '../assets/connector';

export function ManagementPanel({menu}){
    const nav = useNavigate();
    const checkStatus = useResponseStatusCheck();

    const onEmployees=()=>{
        nav("/main/employees")
    }
    const onChannels =()=>{
        nav("/ch/channels")
    }

    function onEmployee(employee){
        nav("/main/employee",{state:{employee:employee}})
    }
    function onChannel(id){

    }
    function fetchEmployees(){
      GetHttp("/employee",{size:5,sort:"id,DESC"})
      .then(e=>{
        checkStatus(e.status);
          if(e.ok){
              e.json().then(json=>setEmployee(json));
          }
      });
      
  }
  function fetchChannels(){
    GetHttp("/channel",{size:5,sort:"id,DESC"})
    .then(res=>{
        if(res.ok){
            res.json().then(json=>setChannels(json));
        }})        
}
  useEffect(()=>{
    fetchEmployees();
    fetchChannels();
  },[])
    const [employee,setEmployee] = useState({content:[]})
    const [channels,setChannels] = useState({content:[]})

    return(<>
        <MenuLayout menu={menu}>
                <div className=' p-2 overflow-scroll'>
                    <div className='d-flex'>
                    <Col className='mx-1 p-1 bg-white'>
                        <div className='text-center text-decoration-underline'>
                            <b>Список содрудников</b><Button variant='link' onClick={onEmployees}> ({employee.totalElements})</Button>
                        </div>
                        <ListGroup variant="flush">
                            {employee.content.map((r,i)=>{
                               const name = r.firstName===null?"":r.firstName;
                               const lastName =r.lastName === null? "":r.lastName;
                                return(
                                    <ListGroup.Item key={i}>
                                        <Button variant='link p-0' onClick={()=>onEmployee(r)}>{name+" "+lastName}</Button>
                                    </ListGroup.Item>
                                )
                            })}
                        </ListGroup>
                    </Col>
                    <Col className='mx-1 p-1 bg-white'>
                    <div className='text-center text-decoration-underline'>
                        <b>Список Каналов</b><Button variant='link' onClick={onChannels}> ({channels.totalElements})</Button>
                    </div>
                        <ListGroup variant="flush">
                            {channels.content.map((d,i)=><ListGroup.Item key={i}><Button variant='link p-0'>{d.name+" : "+d.channelType}</Button> </ListGroup.Item>)}
                        </ListGroup>
                    </Col>
                    </div>
                </div>
                <BidsChart />
            
        </MenuLayout>    
        
    </>)

}
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

function BidsChart(){
    const inputData = [{name:'January',count1:43,count2:12}, {name:'February',count1:45,count2:33}, {name:'March',count1:203,count2:134}];
    const options = {
        
        elements: {
          bar: {
            borderWidth: 2,
          },
        },
        responsive: true,
        plugins: {
          legend: {},
          title: {
            display: true,
            text: 'Прием заявок',
          },
        },
      };

      
    const data = {
        labels:inputData.map(e=>e.name),
        datasets: [
          {
            label: 'Принято',
            data: inputData.map((e) => e.count1),
            backgroundColor: 'rgb(255, 99, 132)',
          },
          {
            label: 'Закрыто',
            data: inputData.map((e) => e.count2),
            backgroundColor: 'rgb(75, 192, 192)',
          }
        ],
      };

      

      return(<div className='bg-white p-1 m-1' style={{width:"600px",height:"300px"}}><Bar data={data} options={options}/></div>)
    

}

